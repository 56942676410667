import React, {useContext, useEffect, useState} from 'react';
import './Agenzie.css';
import config from '../../../../config';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { AuthContext } from '../../../../contexts/authContext';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import IconButton from '@mui/material/IconButton';
import { 
	AddIcon, 
	EditIcon 
} from '../../../../assets/icons/icons';
import { NotificationManager } from 'react-notifications';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import { 
	DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel,
	GridToolbarContainer,
	GridToolbarExport,
} from '@mui/x-data-grid-premium';
import { getFormatDateFromDate } from '../../../../utils/CommonUtilities.ts';

LicenseInfo.setLicenseKey(
	'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

const ActionHeader:any = () => {
	const navigate = useNavigate();
	return <IconButton onClick={()=>navigate('/agenzie/new')}><AddIcon addClass='add-new-row font-16'/></IconButton>
}

const ActionCell:any = (params:any) => {
	const navigate = useNavigate();
	return <IconButton onClick={()=>{navigate(`/agenzie/${params.id}`)}}><EditIcon addClass='font-16'/></IconButton>
}

const PercentCell:any = (params:any) => {
	if (params.value) {
		return <>{(params.value+'')?.replace('.', ',')} %</>
	}
}

const columns:GridColDef[] = [
    { field: 'DAGENZIA', headerClassName: 'header-cell agenziaDesc', headerName: "Nome", type: 'text', width: 200, editable: false},
    { field: 'DAGENZIA_PARENT', headerClassName: 'header-cell agenziaParentDesc', headerName: "Agenzia Padre", type: 'text', width: 100, editable: false},
    { field: 'DN_PERC', headerClassName: 'header-cell dn', headerName: "DN", type: 'text', width: 100, editable: false, align:'right', headerAlign: 'right',
      renderCell: (params:any) => PercentCell(params)},
    { field: 'INDIRIZZO', headerClassName: 'header-cell indirizzo', headerName: "Indirizzo", type: 'text', width: 200, editable: false},
    { field: 'CAP', headerClassName: 'header-cell cap', headerName: "Cap", type: 'text', width: 70, editable: false},
    { field: 'LOCALITA', headerClassName: 'header-cell localita', headerName: "Località", type: 'text', width: 100, editable: false},
    { field: 'SIGLA_PROVINCIA', headerClassName: 'header-cell provincia', headerName: 'Provincia', type: 'text', width: 100, editable: false},
    { field: 'TELEFONO', headerClassName: 'header-cell telefono', headerName: "Telefono", type: 'text', width: 100, editable: false},
    { field: 'E_MAIL', headerClassName: 'header-cell email', headerName: "Email Amministrativa", type: 'text', width: 200, editable: false},
    { field: 'PEC_MAIL', headerClassName: 'header-cell pecMail', headerName: "Pec", type: 'text', width: 230, editable: false},
    { field: 'CODICE_FISCALE', headerClassName: 'header-cell codiceFiscale', headerName: "Codice Fiscale", type: 'text', width: 200, editable: false},
    { field: 'PARTITA_IVA', headerClassName: 'header-cell partitaIva', headerName: "Partita Iva", type: 'text', width: 150, editable: false},
    { field: 'CODICE_SDI', headerClassName: 'header-cell codiceSdi', headerName: "Codice SDI", type: 'text', width: 100, editable: false},
    { field: 'ACTION', headerClassName: 'header-cell action', headerName: "", type: 'text', width: 60, align: 'center', headerAlign: 'center', editable: false, sortable: false,
      renderCell: (params:any) => ActionCell(params),
      renderHeader: () => ActionHeader(),
	  disableColumnMenu: true,
	  disableExport: true}
];

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

function Agenzie(props:any) {

    const { setPageName } = useContext(AppAuthGlobalVariables);
    const auth = useContext(AuthContext)
    const urlParams = useParams();
    const [ rows,     setRows     ] = useState<GridRowModel[]>([]);
    const [ loading,  setLoading  ] = useState(true);
    const [ onReload, setOnReload ] = useState<any>(null)

    useEffect(() => {
      setPageName?.(urlParams['*']);
      async function init(){
        await getAgenzie();
      }
      init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(() => {
        if (onReload !== null) setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[rows])

    const getAgenzie = async () => {
        setOnReload(!onReload);
      	try{
			let axiosParams = {
				url: 'agenzie',
				method: 'get',
				baseURL: config.API_URL,
				headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`}
			}
  
        	let response = await axios(axiosParams);

			if(response.status === 200){
				setRows(response.data);
			}
		}catch (e:any){
			setLoading(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
    }

    return (
      	<div className='component-container agenzie'>
        	{ loading && <CircleProgress/> }
        	<div className='component-card'>
			<DataGridPremium
				className='dataGrid'
				slots={{
					noRowsOverlay: NoRowsDataGridComponent,
					toolbar: CustomToolbar
				}}
				initialState={{ pinnedColumns: { left: ['ACTION'], right: [] } }}
				rows={rows}
				columns={columns}
				rowHeight={30}
				hideFooter={true}
				isCellEditable={(params) => params.id!==0}
				disableColumnMenu={false}
				disableColumnReorder={true}
				disableAggregation={true}
			/>
        	</div>
      	</div>
    )
}

export default Agenzie