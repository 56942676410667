import React, { useState, useContext } from 'react'
import CircleProgress from '../../components/CircleProgress/CircleProgress';
import SySbackground from '../../components/SySbackground/SySbackground';
import FormControl from '@mui/material/FormControl';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyIcon from '@mui/icons-material/Key';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DoomLogo from '../../assets/svgs/DOOM-logo.svg';
import sysLogo from '../../assets/svgs/systrategy_logo.svg';
import './ForgotPassword.css';
import { AuthContext } from '../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import { testEmail, testPasswordValidator } from '../../utils/CommonUtilities';

function ForgotPassword() {

    const navigate = useNavigate()
    const auth = useContext(AuthContext);
    const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [codeSent, setCodeSent] = useState(false);

    const requestCode = async() => {
        try{
            await auth.sendCode(email);

            NotificationManager.info("È stata inviata una mail contenente il codice.");
            setCodeSent(true);
        }catch(e:any){
            NotificationManager.error('Email non esistente', '', 10000);
        }
    }

    const forgotPassword = async() => {
        try{
            await auth.forgotPassword(email, code, newPassword);
            NotificationManager.success("Procedura completata.");
            navigate('/signin');
        }catch(e:any){
            NotificationManager.error('Codice non valido o Password non conforme', '', 10000);
        }
    }

    return (
        <div className='forgotPassword'>
            {forgotPasswordLoading && (<CircleProgress isDialog={true}/>)}
            <div className="forgotPassword-outer-container">
                <SySbackground/>
                <div id="forgotPassword-form" className="forgotPassword-container">
                    <div className="side left forgotPassword-content">
                        <div className="app-login-header">
                            <span className="login-media">Business</span>
                            <span className="login-anal">Manager</span>
                        </div>
                        <div className="user-psw-container">
                    
                            {!codeSent&&(<>
                                <div className="">
                                    <FormControl className="forgotPassword-credentials-wrapper top">
                                        <Input
                                            name            ="email"
                                            value           ={ email }
                                            placeholder     ="Email"
                                            spellCheck      ={ false }
                                            onChange        ={ e => { setEmail(e.target.value); } }
                                            startAdornment  = {
                                                <InputAdornment position="start">
                                                    <AccountCircleIcon />
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div className="button-wrapper">
                                    <Button
                                        variant     = "outlined"
                                        type='submit'
                                        className   ={ "text-uppercase forgotPassword-btn" }
                                        onClick={requestCode}
                                        disabled={!testEmail(email)}
                                    >Richiedi</Button>
                                    <div
                                        className   ={ "text-uppercase forgot-password-label" }
                                        onClick     ={ ()=>navigate('/signin') }
                                    >Login</div>
                                </div>
                            </>)}

                            {codeSent&&(<>
                                <div className="">
                                    <FormControl className="forgotPassword-credentials-wrapper">
                                        <Input
                                            name            ="code"
                                            id              ="code"
                                            placeholder     ="Codice"
                                            type            ='text'
                                            value           ={ code }
                                            onChange        ={ e => { setCode(e.target.value); } }
                                        />
                                    </FormControl>
                                    <FormControl className="forgotPassword-credentials-wrapper">
                                        <Input
                                            name            ="new-password"
                                            id              ="new-password"
                                            placeholder     ="Nuova Password"
                                            type            ={ showNewPassword ? 'text' : 'password' }
                                            value           ={ newPassword }
                                            onChange        ={ e => { setNewPassword(e.target.value); } }
                                            spellCheck      ={ false }
                                            startAdornment  ={
                                                <InputAdornment position="start">
                                                    <KeyIcon />
                                                </InputAdornment>
                                            }
                                            error={newPassword !== confirmPassword || !testPasswordValidator(newPassword)}
                                            endAdornment    ={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label ="toggle password visibility"
                                                        onClick    ={ e => { setShowNewPassword(!showNewPassword); } }
                                                    >
                                                        { showNewPassword ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                    <FormControl className="forgotPassword-credentials-wrapper">
                                        <Input
                                            name            ="confirm-password"
                                            id              ="confirm-password"
                                            placeholder     ="Conferma Password"
                                            type            ={ showConfirmPassword ? 'text' : 'password' }
                                            value           ={ confirmPassword }
                                            onChange        ={ e => { setConfirmPassword(e.target.value); } }
                                            spellCheck      ={ false }
                                            error={newPassword !== confirmPassword || !testPasswordValidator(confirmPassword)}
                                            startAdornment  ={
                                                <InputAdornment position="start">
                                                    <KeyIcon />
                                                </InputAdornment>
                                            }
                                            endAdornment    ={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label ="toggle password visibility"
                                                        onClick    ={ e => { setShowConfirmPassword(!showConfirmPassword); } }
                                                    >
                                                        { showConfirmPassword ? <VisibilityIcon/> : <VisibilityOffIcon/> }
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </div>
                                <div className="button-wrapper">
                                    <Button
                                        variant     = "outlined"
                                        type='button'
                                        className   ={ "text-uppercase forgotPassword-btn" }
                                        onClick={forgotPassword}
                                        disabled={code==='' || (newPassword !== confirmPassword) || newPassword === '' || confirmPassword==='' || !testPasswordValidator(newPassword) || !testPasswordValidator(confirmPassword)}
                                    >Conferma</Button>
                                    <div className='forgot-extra-btns'>
                                        <div
                                            className   ={ "text-uppercase forgot-password-label" }
                                            onClick     ={ ()=>{setEmail('');setCodeSent(false)} }
                                        >
                                            Rinvia Codice
                                        </div>
                                        <div
                                            className   ={ "text-uppercase forgot-password-label" }
                                            onClick     ={ ()=>navigate('/signin') }
                                        >Login</div>
                                    </div>
                                </div>
                            </>)}
                        </div>
                        <div className="footer-info top-bottom-section">
                            <a href="https://www.systrategy.it" target="_blank" rel="noreferrer">
                                <img
                                    src         ={sysLogo}
                                    alt         ="Systrategy"
                                    draggable   ="false"
                                    className   ="systrategy-logo"
                                />
                            </a>
                        </div>
                    </div>
                    <div className='side right only-logo'>
                        <img draggable="false" className='logo-lg' src={DoomLogo} alt="CRM Analyzer"/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword