import React, { useState, useEffect, useContext, useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import Testata from './dettaglio/Testata';
import Proposta from './dettaglio/Proposta';
import PianoFatturazione from './dettaglio/PianoFatturazione';
import Allegati from './dettaglio/Allegati';
import Logs from './dettaglio/Logs';
import { useNavigate } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import config from '../../../../config';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/authContext';
import { formatNumbersIt, getDateFromString, getFormatDate, getFormatDateTime, getHoursUtc, getStringFromDate,formatNumbersItNoSeparator } from '../../../../utils/CommonUtilities';
import InputLabel                           from '@mui/material/InputLabel';
import MenuItem                             from '@mui/material/MenuItem';
import FormControl                          from '@mui/material/FormControl';
import Select       from '@mui/material/Select';
import {
	Dialog
   ,DialogActions
   ,DialogContent
   ,DialogTitle
   ,Button
   ,IconButton
} from '@mui/material';
import { ArrowLeft, DownLoadIcon, SaveIcon, XIcon } from '../../../../assets/icons/icons';
import Divider from '@mui/material/Divider';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';

function WrapperProposta(props:any) {

	const propostaRef = useRef<any>(null);
	const { userEmail, setPageName } = useContext(AppAuthGlobalVariables);

	const auth = useContext(AuthContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [tab, setTab] = useState(0);
	const urlParams: any = {id: window.location.href.split('/').slice(-1)[0]};

    const [ kProposta                , setKProposta              ] = useState<any>('');

	const [dProposta, setDProposta] = useState<string>('');
	const [kClienteSelected, set_kClienteSelected] = useState<any>('');
	const [kAgenziaSelected, set_kAgenziaSelected] = useState<any>('');
	const [kSoggFattSelected, set_kSoggFattSelected] = useState<any>('');
	const [tipoSoggFattSelected, setTipoSoggFattSelected] = useState<string | undefined>('');
	const [dataProp, setDataProp] = useState<any>('');
	const [oPeriodo, set_oPeriodo] = useState<any>({ sDataInizio: '', sDataFine: '' });
	const [kReferenteProgetto, setKReferenteProgetto] = useState<any>('');
	const [kTalentManager, set_kTalentManager] = useState<any>('');
	const [kRefCommProgetto, set_kRefCommProgetto] = useState<any>('');
	const [bBudget, set_bBudget] = useState<any>(false);
	const [fatturazioneDirettaTalent, setFatturazioneDirettaTalent] = useState<any>(false);

	const [note, setNote] = useState<string>('');
	const [kPropStatus, setKPropStatus] = useState('');
	const [dPropStatus, setDPropStatus] = useState('');
	const [kDipartimento, setKDipartimento] = useState('');
	const [kSettore, setKSettore] = useState('');
	const [kCategoria, setKCategoria] = useState('');

	const [loadingCategorie, setLoadingCategorie] = useState(false);

	const [kStatusAttualeProposta, setKStatusAttualeProposta] = useState<string>('');
	const [dStatusAttualeProposta, setDStatusAttualeProposta] = useState<string>('');

	const [budget, setBudget] = useState<string | undefined>(undefined);
	const [dn, setDn] = useState<string | undefined>('');
	const [target, setTarget] = useState<string | undefined>('');

	const [totaleRicavi, setTotaleRicavi] = useState<string | undefined>('');

	const [margineDoomPerc, setMargineDoomPerc] = useState<string | undefined>('');
	const [margineDoom, setMargineDoom] = useState<any>(undefined);

	const [ aoClienti                , set_aoClienti             ] = useState<any>([]);
	const [ aoAgenzie                , set_aoAgenzie             ] = useState<any>([]);
	const [ referentiProgetto        , setReferentiProgetto      ] = useState<any>([]);
	const [ aoTalentManagers         , set_aoTalentManagers      ] = useState<any>([]);
	const [ aoRefCommProgetto        , set_aoRefCommProgetto     ] = useState<any>([]);

	const [ dipartimenti, setDipartimenti ] = useState<any>([]);
	const [ settori, setSettori ] = useState<any>([]);
	const [ categorie, setCategorie ] = useState<any>([]);

	const [nations, setNations] = useState<any>('');
    const [comuni, setComuni] = useState<any>('');

	const [openDialogDn, setOpenDialogDn] = useState<any>(false);

	const [loadingDialogDn, setLoadingDialogDn] = useState<any>(false);
 
	const [dnOriginaleAgenzia, setDnOriginaleAgenzia] = useState<any>(''); 

	const [statiProposta, setStatiProposta] = useState<any>([]);
	const [kStatoProposta, setKStatoProposta] = useState<any>('');

	const [dnDialogHasBeenAsked, setDnDialogHasBeenAsked] = useState(false);

    const proposalShouldBeDisabled = !!(kPropStatus === '04' || kPropStatus === '06');
	const proposalTotalDisabled = !!(kPropStatus === '05');

	const [lastEditDate, setLastEditDate] = useState('');
	const [lastModMadeBy, setLastModMadeBy] = useState('');
	const [createdBy, setCreatedBy] = useState('');

	const [openDialogTalentManager, setOpenDialogTalentManager] = useState(false);

	const [kTalentManagerFromGet, setKTalentManagerFromGet] = useState('');

	const getAnagrafica = ({
		url = ''
		, method = 'get'
		, baseURL = config.API_URL
		, headers = { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` }
		, params = {}
	}) => {
		return async () => {
			try {
				const axiosParams = { url, method, baseURL, headers, params };
				const response = await axios(axiosParams);
				if (response.status === 200) {
					let data = response.data || [];

					if(url === 'clienti'){
						set_aoClienti(data);
						return data;
					}else if(url === 'agenzie'){
						set_aoAgenzie(data);
						return data;
					}else if(url === 'refs-progetto'){
						setReferentiProgetto(data);
					}else if(url === 'users'){
						return response.data;
					}else if(url === 'dipartimenti'){
						setDipartimenti(data);
					}else if(url === 'settori'){
						setSettori(data);
					}
				} else {
					throw new Error('Fallito caricamento');
				}

			} catch (e:any) {
				if(e.response.status===500){
					NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
				}else if(e.response.status===401){
					NotificationManager.error('Non Autorizzato', 'Errore', 10000);
					props.logOut();
				}else{
					NotificationManager.error('Errore di Rete', 'Errore', 10000);
				}
				return [];
			}
		}
	}

	const getAllAnagrafiche = async () => {
		async function clienti() { await getAnagrafica({ url: 'clienti' })() };
		async function agenzie() { await getAnagrafica({ url: 'agenzie' })() };
		async function refProg() { await getAnagrafica({ url: 'refs-progetto' })() };
		async function users() {
			const aoUsers: any = await getAnagrafica({ url: 'users' })();
			set_aoTalentManagers(aoUsers.filter((o: any) => o.FLAG_TALENT_MANAGER === 'Y'));
			set_aoRefCommProgetto(aoUsers.filter((o: any) => o.FLAG_REF_COMM === 'Y'));
		};
		async function dipartimenti() { await getAnagrafica({ url: 'dipartimenti' })() };
		async function settori() { await getAnagrafica({ url: 'settori' })() };

		let savedNations:any = localStorage.getItem('nazioni');
		let savedComuni:any = localStorage.getItem('comuni');

		setNations(JSON.parse(savedNations));
		setComuni(JSON.parse(savedComuni));

		await Promise.all([clienti()
			, agenzie()
			, refProg()
			, users()
			, getStatiProposta()
			, dipartimenti()
			, settori()
		]).then(values => {
			if (urlParams.id !== 'new') {
				getProposta();
			} else {
				setLoading(false);
			}
		}).catch((e:any) => {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		})
	}
	//i force servono in fase di ins-proposta
	const getStatiProposta = async (forceKprop:number | boolean = false) => {
		try {

			let axiosParams = {
				url: 'proposta-stati',
				method: 'get',
				baseURL: config.API_URL,
				headers: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
				params: {
					kProposta: forceKprop ? forceKprop : urlParams.id
				}
			}

			if(axiosParams.params.kProposta === 'new')return;

			let response = await axios(axiosParams);

			if (response.status === 200) {
				setStatiProposta(response.data);
			}
		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	const getCategorie = async(settoreVal:any) => {
		if(!settoreVal)return;
		try {
			setLoadingCategorie(true);
			let axiosParams = {
				url: 'categorie',
				method: 'get',
				baseURL: config.API_URL,
				headers: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
				params: {
					kSettore: settoreVal
				}
			}

			let response = await axios(axiosParams);

			if (response.status === 200) {
				setCategorie(response.data);
			}
			setLoadingCategorie(false);
		} catch (e:any) {
			setLoadingCategorie(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	useEffect(() => {
		setLoading(true);
		(async function () {
			await getAllAnagrafiche();
		})();

		if (urlParams.id === 'new') {
			setDataProp(new Date());
			setKProposta('new');
			setPageName?.('Nuova Proposta');
		} else {
			setKProposta(urlParams.id);
			setPageName?.('Proposta - ' + urlParams.id);
		}
	}, []);

	useEffect(() => {
		if(urlParams.id === 'new'){
			if(localStorage.getItem('referente_commerciale')==='Y'){
				let utenteCorrente = aoRefCommProgetto.find((tm:any) => tm.EMAIL_ADDRESS === userEmail);
				if(utenteCorrente){
					set_kRefCommProgetto(utenteCorrente.id);
				}
			}else{
				//se l'utente non è talent manager seleziona tutti
				set_kRefCommProgetto('');
			}
		}
	}, [aoRefCommProgetto])

	const getProposta = async (forceKprop:number | boolean = false) => {
		try {
			let axiosParams = {
				url: 'proposte',
				method: 'get',
				baseURL: config.API_URL,
				headers: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
				params: { kProposta: forceKprop ? forceKprop : urlParams.id }
			}

			let response = await axios(axiosParams);

			if (response.status === 200) {
				const data = response.data[0];
				setDProposta(data.DPROPOSTA);
				setDataProp(getDateFromString(data.DATA_PROPOSTA));
				set_kClienteSelected(data.KCLIENTE);
				set_kAgenziaSelected(data.KAGENZIA);
				set_kSoggFattSelected(data.KINTEST_FATT);
				set_oPeriodo({ sDataInizio: data.BEGIN_DATE, sDataFine: data.END_DATE });
				setKReferenteProgetto(data.KREF_PROG);
				set_kTalentManager(data.KTALENT_MANAGER);
				set_kRefCommProgetto(data.KREF_COMM);
				set_bBudget(data.FLAG_BUDGET === 'Y' ? true : false);
				setFatturazioneDirettaTalent(data.FLAG_FATT_DIR_TALENT === 'Y' ? true : false);
				//set_kStatoProp(data.KSTATO);
				let replaceDn = formatNumbersIt(data.DN_PERC);
				let replaceMargineDoom = formatNumbersIt(data.DOOM_PERC);
				setDn(replaceDn);
				setMargineDoomPerc(replaceMargineDoom);
				let replaceBudget:any = data.BUDGET ? formatNumbersItNoSeparator(data.BUDGET) : ''
				setBudget(replaceBudget);
				setNote(data.NOTE);

				setMargineDoom(data.DOOM_MARG);

				setTotaleRicavi(data.TOT_RICAVI);

				setTipoSoggFattSelected(data.TYPE_INTEST_FATT);

				setKPropStatus(data.KSTATO);
				setDPropStatus(data.DSTATO);

				setKStatusAttualeProposta(data.KSTATO);
				setDStatusAttualeProposta(data.DSTATO);
				
				setLastEditDate(getFormatDateTime(data.DATA_ULTIMA_MOD) || '');
				setLastModMadeBy(data.USER_ULTIMA_MOD);
				setCreatedBy(data.USER_INS || '');

				setKTalentManagerFromGet(data.KTALENT_MANAGER);

				setKDipartimento(data.KDIPARTIMENTO);
				setKSettore(data.KSECTOR);
				await getCategorie(data.KSECTOR);
				setKCategoria(data.KCATEGORY);

				setTarget(data.TARGET);
				/* questo if disturba più che ad aiutare, renderlo più efficace
				if(props.menuVoiceSelected==='04'){
					setTab(2)
				} */
			}
		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
		setLoading(false);
	}

	const handleCheckAgenziaDn = async(isFromProposalBody:boolean = false) => {
		let agenziaSelezionata = aoAgenzie.find((az:any) => az.id===kAgenziaSelected) || '';
		let originaleAgenziaObj = agenziaSelezionata.DN_PERC || '';

		let dnFromState:any = dn;

		setDnOriginaleAgenzia(originaleAgenziaObj);

		if(!originaleAgenziaObj){
			originaleAgenziaObj = '0';
		}else{
			originaleAgenziaObj = originaleAgenziaObj.toString();
		}

		if(!dnFromState){
			dnFromState = '0';
		}else{
			dnFromState = dnFromState.toString();
		}

		//se c'è differenza nel dn ed il popUp non è stato chiesto
		if(!!(((originaleAgenziaObj !== dnFromState) && kAgenziaSelected)&&!dnDialogHasBeenAsked)){

			setDnDialogHasBeenAsked(true);
			if(!(kPropStatus === '04' || kPropStatus === '06')){
				setOpenDialogDn(true);
			}else{

				//se c'è differenza ma la proposta è in stato contrattualizzato o contrattualizzato in modifica
				await submitProcedure();
				//se la proposta è contrattualizzata
				if(kPropStatus==='04'){
					await alterStatusProposta('06');
				}
			}
		}else{

			if(tab===0 && !((kPropStatus === '04') || (kPropStatus === '06')) ){
				submitProcedure();
			}else if(isFromProposalBody){
				//entra qua nel caso di modifica dal proposal body
				await submitProcedure();
				//se è in stato contrattualizzato, diventa contrattualizzato in modifica
				if(kPropStatus==='04'){
					await alterStatusProposta('06');
				}
			}else if(tab===0 && ((kPropStatus === '04') || (kPropStatus === '06')) && kStatusAttualeProposta !== '05'){
				//se siamo in testata e la proposta è contrattualizzata o contrattualizzata in modifica
				//e non si vuole passare in chiusa
				//allora significa che stanno modificando il talent manager
				await submitProcedure();
			}else{
				//in casi non intercettati viene effettuato un alter status che in teoria non modifica nulla
				//entra qua nel caso di modifica dalla testata o dalla tendina
				//await submitProcedure();
				//tecnicamente non serve questo if
				//al momento disabilito quei campi di testata... troppo confusionario avere un unico tasto... per modificare questi campi lo si fa in corpo proposta
				// if(kPropStatus==='04'){
					if(tab === 0){
						await submitProcedure();
					}else{
						await alterStatusProposta();
					}
				//}
			}
		}
	}

	const alterStatusProposta = async(forcedStatus:boolean | string = false) => {
		setLoading(true);
		handleCloseDialogDn();
		try {
			let response = undefined;

			const params = {
				kProposta,
				kNextStato: forcedStatus ? forcedStatus : kStatusAttualeProposta,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
			}

			let axiosParams = {
				url: 'proposta-mod-status',
				method: 'post',
				baseURL: config.API_URL,
				headers: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
				data: {
					...params
				}
			}

			response = await axios(axiosParams);

			if (response.status === 200) {
				if(forcedStatus==='06'){
					NotificationManager.warning('Status Proposta Aggiornato a Contrattualizzata in Modifica.', '', 5000);
				}else{
					NotificationManager.success('Status Proposta Aggiornato', '', 5000);
				}
				getStatiProposta();
				getProposta();
			}

		}catch(e:any){
			setKStatusAttualeProposta(kPropStatus);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
		setLoading(false);
	}

	const submitProcedure = async () => {
		setLoading(true);
		try {
			handleCloseDialogDn();
			let response = undefined;

			const propostaParams: any = {
				dProposta: dProposta,
				dataProposta: typeof dataProp === 'string' ? dataProp : getStringFromDate(dataProp),
				kCliente: kClienteSelected,
				kAgenzia: kAgenziaSelected,
				kIntestatarioFattura: kSoggFattSelected,
				tipoIntestatarioFattura: tipoSoggFattSelected,
				beginDate: oPeriodo.sDataInizio,
				endDate: oPeriodo.sDataFine,
				kReferenteProgetto: kReferenteProgetto,
				kTalentManager: kTalentManager,
				kReferenteCommerciale: kRefCommProgetto,
				flagBudget: bBudget ? 'Y' : 'N',
				flagFatturazioneDirettaTalent: fatturazioneDirettaTalent ? 'Y' : 'N',
				note: note,
				margineDoom: (margineDoomPerc+'')?.replace(',', '.'),
				budget: bBudget ? (budget+'')?.replace(',', '.') : '',
				target: bBudget ? '' : target,
				dn: !kAgenziaSelected ? '' : (dn+'')?.replace(',', '.'),
				kStatoProp: kStatusAttualeProposta,
				kDipartimento,
				kSettore,
				kCategoria,
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
			}

			let axiosParams = {
				url: '',
				method: 'post',
				baseURL: config.API_URL,
				headers: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
				data: {
					...propostaParams
				}
			}

			if (!kProposta || kProposta === 'new') {
				axiosParams.url = 'proposta-ins';
				response = await axios(axiosParams);
			} else {
				axiosParams.url = 'proposta-mod';
				axiosParams.data.kProposta = kProposta;
				response = await axios(axiosParams);
			}

			if (response.status === 200) {
				NotificationManager.success('Procedura Completata', '', 5000);
				if (!kProposta || kProposta === 'new') {
					setKProposta(response.data.retVal);
					let posizione:any = window.location.href.split('/').slice(0,-1);
					posizione.push(response.data.retVal);
					posizione = posizione.join('/');
					window.history.pushState({}, 'Business Manager', posizione);
					setPageName?.('Proposta - ' + response.data.retVal);
					await getProposta(response.data.retVal);
					await getStatiProposta(response.data.retVal);
				}else{
					getProposta();
				}
				if(tab===1)propostaRef.current.loadRigheDettaglio();

				getStatiProposta();
			}
		} catch (e: any) {
			setKStatusAttualeProposta(kPropStatus);
			if(e.response.status===500){				
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
		setLoading(false);
	}

	const modAgenziaDn = async () => {
        try{
			setLoadingDialogDn(true);
			let agenziaSelezionata = aoAgenzie.find((az:any) => az.id === kAgenziaSelected);
            let response = undefined;

            const agentData:any = {
				kAgenzia: agenziaSelezionata.id,
                dAgenzia: agenziaSelezionata.DAGENZIA || '',
                kAgenziaParent: agenziaSelezionata.KAGENZIA_PARENT || '',
                kNazione: agenziaSelezionata.KNAZIONE || '',
                kComune: agenziaSelezionata.KCOMUNE || '',
                indirizzo: agenziaSelezionata.INDIRIZZO || '',
                cap: agenziaSelezionata.CAP || '',
                localita: agenziaSelezionata.LOCALITA || '',
                telefono: agenziaSelezionata.TELEFONO || '',
                email: agenziaSelezionata.E_MAIL || '',
                pec: agenziaSelezionata.PEC_MAIL || '',
                codiceFiscale: agenziaSelezionata.CODICE_FISCALE || '', 
                partitaIva: agenziaSelezionata.PARTITA_IVA || '',
                sdi: agenziaSelezionata.CODICE_SDI || '',
                dn: dn,
                flagBypassCheckCF: 'Y',
                flagBypassCheckPiva: 'Y',
				impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }

            let axiosParams = {
                url: 'agenzia-mod',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...agentData
                }
            }

			response = await axios(axiosParams);

            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ){
                NotificationManager.success('DN Aggiornato', '', 5000);
				await getAnagrafica({ url: 'agenzie' })();
				setLoadingDialogDn(false);
				if(tab===0 && !((kPropStatus === '04') || (kPropStatus === '06'))){
					submitProcedure();
				}else{
					alterStatusProposta();
				}
            }
        } catch (e: any) {
			setLoadingDialogDn(false);
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
        }
    }

    const goToList = () => {
		if(props.menuVoiceSelected==='scadenzario'){
			navigate('/scadenzario');
		}else{
        	navigate(`/proposte${props.menuVoiceSelected!=='tutte' ? '/'+props.menuVoiceSelected : ''}`);
		}
    }

	const handleCloseDialogDn = () => {
		setOpenDialogDn(false);
	}

	const handleCloseDialogTalentManager = () => {
		setOpenDialogTalentManager(false);
	}

	const requestPreventivoProposta = async() => {

		try{
			const findCliente = aoClienti.find((cl:any) => cl.id === kClienteSelected);
			const findAgenzia = aoAgenzie.find((ag:any) => ag.id === kAgenziaSelected);

			const intestatarioFatturaSelected = tipoSoggFattSelected === 'CL' ? aoClienti.find((cl:any) => cl.id === kSoggFattSelected) : aoAgenzie.find((ag:any) => ag.id === kSoggFattSelected);
			const intestatarioFatturaCF = intestatarioFatturaSelected.CODICE_FISCALE;
			const intestatarioFatturaIVA = intestatarioFatturaSelected.PARTITA_IVA;
			
			const checkIvaAndCf = ((intestatarioFatturaCF === intestatarioFatturaIVA) && intestatarioFatturaCF && intestatarioFatturaIVA) 
																? `p. iva e c.f. ${intestatarioFatturaIVA}` 
															: !intestatarioFatturaCF && !intestatarioFatturaIVA
																? ''
															: intestatarioFatturaCF && !intestatarioFatturaIVA
																? `c.f. ${intestatarioFatturaCF}`
															: !intestatarioFatturaCF && intestatarioFatturaIVA
																? `p. iva ${intestatarioFatturaIVA}`
															: '';

			const talents:string = await getTalentsFromRigheDettaglio();

			const preventivoJson = {
					renderRequest: {	
						template: {
							name: "/proposta/preventivo"
						},
						data:{
							INTEST_FATT: intestatarioFatturaSelected[tipoSoggFattSelected === 'CL' ? 'DCLIENTE' : 'DAGENZIA'],
							isAgenziaSelected: !findAgenzia ? '' : `${findAgenzia.DAGENZIA}, con sede legale in ${(findAgenzia.INDIRIZZO || '[indirizzo agenzia]')} e P.IVA n. ${(findAgenzia.PARTITA_IVA || '[partita iva agenzia]')} e rappresentante legale [rappresentante legale]`,
							BEGIN_DATE: getFormatDate(oPeriodo.sDataInizio),
							END_DATE: getFormatDate(oPeriodo.sDataFine),
							CLIENTE: findCliente.DCLIENTE,
							INDIRIZZO_CLIENTE: (findCliente.INDIRIZZO || '[indirizzo cliente]'),
							talents,
							TOTALE_RICAVI: totaleRicavi ? formatNumbersIt(totaleRicavi) + ' €' : '0 €',
							INDIRIZZO_INTEST_FATT: (intestatarioFatturaSelected.INDIRIZZO || '[indirizzo intestatario fattura]'),
							CAP_INTEST_FATT: (intestatarioFatturaSelected.CAP || '[cap intestatario fattura]'),
							COMUNE_INTEST_FATT: intestatarioFatturaSelected.DCOMUNE,
							checkIvaAndCf,
							CODICE_SDI_INTEST_FATT: (intestatarioFatturaSelected.CODICE_SDI || '[codice sdi intestatario fattura]'),
							PEC_MAIL_INTEST_FATT: (intestatarioFatturaSelected.PEC_MAIL || '[pec mail intestatario fattura]'),
							data: getFormatDate(getStringFromDate(new Date()))
						}
					}
			}

			//QUESTO FUNZIONA PER LE LAMBDA
			/*
			const jsonBlob = new Blob([JSON.stringify(preventivoJson, null, 2)], {
				type: "application/json",
			});
			
			const jsonFile = new File([jsonBlob], "valuesData");
			const result = await fetch(
				`${config.API_URL}/proposta-up-allegato?key=fileTrigger/${kProposta}.json`, {
				method: 'POST',
				headers: {
					"Content-Type": "multipart/form-data",
					Authorization: `Bearer ${auth.sessionInfo?.accessToken}`
				},
				body: jsonFile
			});
			*/
			const jsonBlob = new Blob([JSON.stringify(preventivoJson, null, 2)], {
				type: "application/json",
			});
			
			const jsonFile = new File([jsonBlob], "valuesData");

			const form = new FormData();

			let username = auth.attrInfo[0].Value;

			let oramin = getHoursUtc();

			form.append('file', jsonFile);
			const result = await fetch(
				`${config.API_URL}/proposta-up-allegato?key=fileTrigger/${kProposta}¶${username}¶${oramin}.json`, {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${auth.sessionInfo?.accessToken}`
				},
				body: form
			});

			const message = await result.json();

			let axiosParams = {
                url: 'request-file',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
					username,
					kProposta: kProposta,
					oramin,
					dCliente: findCliente.DCLIENTE
                }
            }

            let response = await axios(axiosParams);

			NotificationManager.info('Richiesta Preventivo inviata', '', 5000);
		}catch(e:any){
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	const getTalentsFromRigheDettaglio = async() => {
		try{
			const paramsRigheDettaglio:any = {
                kProposta: kProposta,
            }

            let axiosParams = {
                url: 'proposta-dettagli',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
                  ...paramsRigheDettaglio
                }
            }

			let response = await axios(axiosParams);

			if(response.status === 200){
				const data = response.data;
				//solo le righe talents
				let arrTalents:any = [];
				const talentRows = data.filter((row:any) => row.TIPO_DETTAGLIO === 1 || row.TIPO_DETTAGLIO === 2);
				for (let index = 0; index < talentRows.length; index++) {
					const element = talentRows[index];
					arrTalents.push(element.NOME_ARTE_TALENT);
				}
				arrTalents = [...new Set(arrTalents)].join(', ');
				return arrTalents;
			}
		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	//'{{AGENZIA}}, con sede legale in {{INDIRIZZO_AGENZIA}} e P.IVA n. {{AGENZIA_PARTITA_IVA}} e rappresentante legale [rappresentante legale]'

	return (
		<div className='component-container proposte-wrapper'>
			{loading && <CircleProgress />}
			<div className='component-card'>
				<div className='nuovaProposta-wrapper'>
					<div className='header-wrapper-proposta'>
						<div>
							<div className='header-proposte'>
								<IconButton title='Indietro' className='back-proposte-lista' onClick={goToList}><ArrowLeft/></IconButton>
								<Tabs
									value={tab}
									onChange={(event, newTab) => { setTab(newTab) }}
									aria-label="tabs"
									className='tabs'
								>
									<Tab label="Progetto" />
									<Tab label="Proposta" disabled={kProposta === 'new'} />
									<Tab label="Piano Fatturazione" disabled={kProposta === 'new'} />
									<Tab label="Allegati" disabled={kProposta=== 'new'} />
									{localStorage.getItem('admin')==='Y'&&<Tab label="Logs" disabled={kProposta === 'new'} />}
								</Tabs>
								{kProposta!=='new'&&(<IconButton title='Scarica Preventivo' onClick={()=>requestPreventivoProposta()}><DownLoadIcon/></IconButton>)}
							</div>
						</div>
						<div className='status-management'>
							{kProposta!=='new'&&(
								<FormControl variant='standard' style={{marginRight:'15px', paddingBottom: '5px'}}>
									<InputLabel id="status-select-label">Status</InputLabel>
									<Select
										sx = { { width: '100%' } }
										labelId="status-select-label"
										id="status-select"
										value={kStatusAttualeProposta}
										onChange={(event)=>{setKStatusAttualeProposta(event.target.value)}}
									> 
										<MenuItem key={kPropStatus} value={kPropStatus}>{dPropStatus}</MenuItem>
										<Divider />
										{statiProposta.map((st:any) => <MenuItem key={st.KSTATO} value={st.KSTATO}>{st.DSTATO}</MenuItem>)}
									</Select>
								</FormControl>
							)}
                        	<IconButton
							 	title='Salva'
								disabled={false} 
								onClick={()=>{
										//bisogna controllare la tab in cui ci si trova poiché in tab 0 faccio la set dei dati di testata,
										//mentre nelle altre pagine non facendo la set del nuovo valore non distinguo correttamente quale sia il kTalentManager che viene scritto
										//quindi nelle altre pagine uso un kTalentManager fixato dalla get
										if(kStatusAttualeProposta === '02' && kPropStatus === '01' && kTalentManager === '3141d23a-4db1-4d70-8bbc-ef01f4291f92' && tab===0){
											setOpenDialogTalentManager(true);
										}else if(kStatusAttualeProposta === '02' && kPropStatus === '01' && kTalentManagerFromGet === '3141d23a-4db1-4d70-8bbc-ef01f4291f92' && tab!==0){
											setOpenDialogTalentManager(true);
										}else{
											handleCheckAgenziaDn();
										}
									}}
							><SaveIcon/></IconButton>
						</div>
					</div>
					{
						tab === 0
							?
							<Testata {...{
								submitProcedure,
								getAnagrafica,
								loading,
								setLoading,
								budget,
								setBudget,
								target,
								setTarget,
								dn,
								setDn,
								margineDoomPerc,
								setMargineDoomPerc,
								dProposta, setDProposta,
								kClienteSelected, set_kClienteSelected,
								kAgenziaSelected, set_kAgenziaSelected,
								kSoggFattSelected, set_kSoggFattSelected,
								tipoSoggFattSelected, setTipoSoggFattSelected,
								dataProp, setDataProp,
								oPeriodo, set_oPeriodo,
								kReferenteProgetto, setKReferenteProgetto,
								kTalentManager, set_kTalentManager,
								kRefCommProgetto, set_kRefCommProgetto,
								bBudget, set_bBudget,
								fatturazioneDirettaTalent, setFatturazioneDirettaTalent,

								note, setNote,
								aoClienti                , set_aoClienti,
								aoAgenzie                , set_aoAgenzie,
								referentiProgetto        , setReferentiProgetto,
								aoTalentManagers         , set_aoTalentManagers,
								aoRefCommProgetto        , set_aoRefCommProgetto,

								nations, setNations,
								comuni, setComuni,
								
								handleCheckAgenziaDn,
								kProposta,
								dnOriginaleAgenzia, setDnOriginaleAgenzia,
								statiProposta,
								kStatoProposta,
								setKStatoProposta,
								kStatusAttualeProposta,
								setKStatusAttualeProposta,
								dStatusAttualeProposta,
								proposalShouldBeDisabled,
								proposalTotalDisabled,
								alterStatusProposta,
								kPropStatus,
								kDipartimento, setKDipartimento, dipartimenti,
								kSettore, setKSettore, kCategoria, setKCategoria, settori, categorie, getCategorie, setCategorie,
								loadingCategorie
							}}/>
							:
							tab === 1
								?
								<Proposta {...{
									submitProcedure,
									loading,
									setLoading,
									bBudget,
									budget,
									setBudget,
									dn,
									setDn,
									margineDoomPerc,
									setMargineDoomPerc,
									handleCheckAgenziaDn,
									kProposta,
									target,
									setTarget,
									proposalShouldBeDisabled,
									proposalTotalDisabled,
									alterStatusProposta,
									kPropStatus,
									setTotaleRicavi,
									kAgenziaSelected
								}} ref={propostaRef}/>
								:
								tab === 2 ?
									<PianoFatturazione {...{ 
										loading,
										setLoading,
										kProposta,
										budget,
										totaleRicavi,
										bBudget,
										kStatusAttualeProposta,
										kPropStatus,
										alterStatusProposta,
										proposalTotalDisabled,
										getProposta,
										fatturazioneDirettaTalent,
										margineDoom }} />
									:
									tab === 3 ?
										<Allegati {...{ loading, setLoading, kProposta }} />
										:
										<Logs {...{ loading, setLoading, lastEditDate, lastModMadeBy, createdBy }} />
					}

				</div>
			</div>
			<Dialog open={ openDialogDn } onClose={ handleCloseDialogDn }>
                <DialogTitle className='dialogueTitleSovrascriviDn'>
                 	Modifica DN
					<IconButton style={{marginLeft: 'auto'}} onClick={handleCloseDialogDn} autoFocus><XIcon/></IconButton>
                </DialogTitle>
                <DialogContent className='align-textifieldAree-center'>
					{loadingDialogDn && <CircleProgress isDialog={true}/>}
                    <span>{`Il DN indicato nella proposta (${dn || 0}%) è diverso da quello indicato sull'agenzia (${dnOriginaleAgenzia || 0}%).`}</span>
					<span>Vuoi mantenere il valore di default dell'agenzia?</span>
                </DialogContent>
                <DialogActions className='flex-box-between dn-mod'>
					<Button onClick={ ()=> {tab === 0 ? submitProcedure() : tab===1 ? handleCheckAgenziaDn(true) : alterStatusProposta()} } variant='contained'>Mantieni</Button>
                    <Button onClick={ modAgenziaDn } variant='contained'>Sovrascrivi</Button>
                </DialogActions>
            </Dialog>

			<Dialog open={ openDialogTalentManager } onClose={ handleCloseDialogTalentManager }>
                <DialogTitle className='talent-manager-check'>
                 	Attenzione
                </DialogTitle>
                <DialogContent className='align-textifieldAree-center'>
					<span>Sulla proposta è ancora indicato il Talent Manager provvisorio.</span>
					<span>Proseguire ugualmente con l'aggiornamento dello stato della proposta?</span>
                </DialogContent>
                <DialogActions className='flex-box-between dn-mod'>
					<Button onClick={ handleCloseDialogTalentManager } autoFocus>Annulla</Button>
                    <Button onClick={ () => {handleCloseDialogTalentManager();handleCheckAgenziaDn()} } variant='contained'>Prosegui</Button>
                </DialogActions>
            </Dialog>
		</div>
	)
}

export default WrapperProposta;