import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { ResponsivePie } from '@nivo/pie';
import { formatNumbersIt } from './CommonUtilities';

function Graph(props:any) {

    if(props.graphType === 'bar'){
        return (
            <ResponsiveBar
                data={props.data}
                indexBy={props.indexBy}
                layout={props.layout}
                groupMode={props.groupMode}
                keys={props.keys}
                margin={props.margin}
                padding={props.padding}
                maxValue={props.maxValue}
                axisBottom={props.axisBottom}
                axisLeft={props.axisLeft}
                label={props.label}
                colors={props.colorFromColumnData === true ? (bar:any) => {return bar.data[`${bar.id}Color`]/*  bar.data.color */} : props.colorFromColumnData}
                colorBy={props.colorBy ? props.colorBy : 'id'}
                tooltip={props.tooltip}
                legends={props.showLegend?[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]: []}
            />
        )
    }else if(props.graphType === 'pie'){
        return (
            <ResponsivePie
                data={props.data}
                margin={props.margin}
                innerRadius={props.innerRadius}
                padAngle={props.padAngle}
                cornerRadius={props.cornerRadius}
                arcLabel={(lbl:any) => `${formatNumbersIt(lbl.value)}%`}
                arcLinkLabel={(lbl:any) => `${lbl.label}: ${formatNumbersIt(lbl.value)}%`}
                colors={{ datum: 'data.color' }}
                tooltip={props.tooltip}
            />
        )
    }else{
        return (<div>No Graph Type</div>)
    }
}

export default Graph