import React, {useContext, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { Button
        ,Dialog
        ,DialogActions
        ,DialogContent
        ,DialogTitle
        ,IconButton
        ,TextField } from '@mui/material';
import axios  		    from 'axios';
import config 		    from '../../../../config';
import CircleProgress   from '../../../../components/CircleProgress/CircleProgress';
import { OptionParams } from '../../../../utils/ConfigInterfaces'
import { AuthContext }  from '../../../../contexts/authContext';
import { AddIcon
       , CheckBoxEmptyIcon
       , CheckBoxFilledIcon
       , EditIcon } from '../../../../assets/icons/icons';
import './CategorieCosti.css';
import '../Options.css';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent.tsx';
import InputLabel                           from '@mui/material/InputLabel';
import MenuItem                             from '@mui/material/MenuItem';
import FormControl                          from '@mui/material/FormControl';
import Select                               from '@mui/material/Select';
import { DataGridPremium,
	LicenseInfo,
	GridColDef,
	GridRowModel
	} from '@mui/x-data-grid-premium';

LicenseInfo.setLicenseKey(
    'a5fb85400407876bcf9c1364b93581a5Tz0xMDEzNzksRT0xNzYyNTM4ODk3MDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y',
);

function CategorieCosti(props:any) {
    const auth = useContext(AuthContext);
    const urlParams = useParams(); 
    const { changePageName } = useContext(AppAuthGlobalVariables);

    const [ openDialog    ,setOpenDialog    ] = useState<boolean | string>(false);
    const [ dialogContent ,setDialogContent ] = useState<OptionParams>({ kCategoriaCosti: '' , dCategoriaCosti: '' });
    const [ selectFlagsCatCosti ,setSelectFlagsCatCosti ] = useState<any>(0);
    const [ rows          ,setRows ] = useState<GridRowModel[]>([]);

    const [ onReload ,setOnReload ] = useState<boolean | null>(null);
    const [ loading  ,setLoading  ] = useState<boolean | null>(null);
    const [ loadingDialog  ,setLoadingDialog  ] = useState<boolean | null>(null);

    const ActionHeader:any = () => {
        return <IconButton onClick={()=>{ handleOpenDialog('new');
                                          setDialogContent({ kCategoriaCosti: 'new' , dCategoriaCosti: '' }) }}>
                    <AddIcon addClass='font-16'/>
                </IconButton>
    }

    const ActionCell:any = ( params:any ) => {
        return <IconButton onClick={()=>{ handleOpenDialog(params.row.DCATEGORIA_COSTI, {flagCalcDn: params.row.FLAG_CALC_DN, flagCalcDoomMarg: params.row.FLAG_CALC_DOOM_MARG});
                                          setDialogContent({ kCategoriaCosti : params.row.id 
                                                           , dCategoriaCosti : params.row.DCATEGORIA_COSTI }) }}>
                <EditIcon addClass='font-16'/>
            </IconButton>;
    }

    const ActiveCell:any = ( params:any ) => {
        return ( params.value
            ? <div className='flexed cursor-pointer' onClick={() => toggleCategoriaCosti(params.id, (params.value ? true : false))}><CheckBoxEmptyIcon addClass='font-22'/></div>
            : <div className='flexed cursor-pointer' onClick={() => toggleCategoriaCosti(params.id, (params.value ? true : false))}><CheckBoxFilledIcon addClass='font-22'/></div>
        )
    }
    
    const ExclusionCell:any = (params:any) => {
        return ( params.row.FLAG_CALC_DN === 'N' && params.row.FLAG_CALC_DOOM_MARG === 'Y'
            ?   <div className='flexed'>Escluso dal calcolo del DN</div>
            :   params.row.FLAG_CALC_DN === 'N' && params.row.FLAG_CALC_DOOM_MARG === 'N'
                ? <div className='flexed'>Escluso dal calcolo del margine e del DN</div>
                : <div className='flexed'>Default</div>
        )
    }

    const columns:GridColDef[] = [
        { field: 'DCATEGORIA_COSTI', headerClassName: 'header-cell descrizioneArea', headerName: "Descrizione", type: 'text', width: 300, editable: false },
        { field: 'esclusione', headerClassName: 'header-cell esclusione', headerName: "Esclusione dai calcoli", type: 'text', width: 300, editable: false, renderCell: (params) => ExclusionCell(params)},
        { field: 'DATA_CANC'       , headerClassName: 'header-cell flagAbilitato'  , headerName: "Attiva"     , type: 'text', width: 60, editable: false, align:'center', headerAlign: 'center',
            renderCell: (params:any) => ActiveCell(params), disableColumnMenu: true},
        { field: 'ACTION', renderHeader: ()=>ActionHeader(), headerClassName: 'header-cell codiceSdi', headerName: "", type: 'text', width: 60, align: 'center', headerAlign: 'center', editable: false, sortable: false,
            renderCell: (params:any) => ActionCell(params),disableColumnMenu: true
        }
    ];

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setDialogContent({});
        setSelectFlagsCatCosti(0);
    }

    const handleOpenDialog = (openVal:any, dataFlags:any = false) => {
        setOpenDialog(openVal);
        if(dataFlags){
            if(dataFlags.flagCalcDn==="N" && dataFlags.flagCalcDoomMarg==="Y"){
                setSelectFlagsCatCosti(1);
            }else if(dataFlags.flagCalcDn==="N" && dataFlags.flagCalcDoomMarg==="N"){
                setSelectFlagsCatCosti(2);
            }
        }else{
            setSelectFlagsCatCosti(0);
        }
    }

    const getCategorieCosti = async () => {
        setLoading(true);

        try {    
            let axiosParams = {
                url		: 'categorie-costi',
                method	: 'get',
                baseURL	: config.API_URL,
                headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
            }
            let response = await axios(axiosParams);

            if (response.status === 200){
                setRows([...response.data]);
            }
        } catch (e:any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoading(false);
        }
    }

    const insCategoriaCosti = async (params: OptionParams) => {

		let axiosParams = {
			url		: 'categoria-costi-ins',
			method	: 'post',
			baseURL	: config.API_URL,
			headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
			data    : { ...params }
		}

		return await axios(axiosParams);
	}

  	const modCategoriaCosti = async (params: OptionParams) => {

        let mapExclusionCalcParam = {flagCalcDn: "Y", flagCalcDoomMarg: "Y"}

        if(selectFlagsCatCosti===1){
            mapExclusionCalcParam.flagCalcDn = "N";
        }else if(selectFlagsCatCosti===2){
            mapExclusionCalcParam.flagCalcDn = "N";
            mapExclusionCalcParam.flagCalcDoomMarg = "N";
        }

		let axiosParams = {
			url		: 'categoria-costi-mod',
			method	: 'post',
			baseURL	: config.API_URL,
			headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
			data    : { ...params, ...mapExclusionCalcParam }
		}

		return await axios(axiosParams);
	}

    const submitCategoriaCosti = async () => {
        setLoadingDialog(true);
        try {
            let response: any = undefined;

            if ( openDialog === 'new' ) {
                response = await insCategoriaCosti(dialogContent);
            } else {
                response = await modCategoriaCosti(dialogContent);
            }

            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ) {
                NotificationManager.success('Procedura Completata', '', 5000);
                handleCloseDialog();
                setOnReload(!onReload);
            } else {
                throw new Error(errorMessage);
            }
            setLoadingDialog(false);
        } catch (e: any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoadingDialog(false);
        }
    }

    const toggleCategoriaCosti = async ( id:number, undo:boolean ) => {
        setLoading(true);
        try {
            let axiosParams = {
                url		: 'categoria-costi-del',
                method	: 'post',
                baseURL	: config.API_URL,
                headers	: { Authorization: `Bearer ${auth.sessionInfo?.accessToken}` },
                data    : { kCategoriaCosti: id, undo }
            }
            let response = await axios(axiosParams);

            let { nRetVal, errorMessage } = response.data;
            if ( response.status === 200 && nRetVal > 0 && !errorMessage ) {
                NotificationManager.success( 'Procedura Completata', '', 5000 );
                handleCloseDialog();
                setOnReload( !onReload );
            } else {
                throw new Error( errorMessage );
            }
        } catch (e: any) {
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        changePageName?.(urlParams['*']);
        getCategorieCosti();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if ( onReload !== null ) {
            getCategorieCosti();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ onReload ]);

    useEffect(() => {
        if ( loading !== null) {
            setLoading(false);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ rows ]);

    return (
        <div className='component-container categorie-costi'>
            { loading && ( <CircleProgress/>)}
            <div className='component-card'>
                <DataGridPremium
                    className            = 'dataGrid'
                    slots                = {{ noRowsOverlay: NoRowsDataGridComponent }}
                    initialState         = {{ pinnedColumns: { left: ['ACTION'], right: [] } }}
                    rows                 = { rows }
                    columns              = { columns }
                    rowHeight            = { 30 }
                    hideFooter           = { true }
                    disableColumnMenu={false}
                    disableColumnReorder={true}
                    disableAggregation={true}
                />
            </div>
            <Dialog open={ !!openDialog } onClose={ handleCloseDialog } className='cat-costi-dialog'>
                <DialogTitle className='dialogueTitleAreeContainer'>
                    { openDialog === 'new' ? 'Nuova Categoria Costi' : openDialog }
                </DialogTitle>
                <DialogContent className='align-textifieldAree-center'>
                    {loadingDialog && <CircleProgress isDialog={true}/>}
                    <TextField
                        style    = {{ width: '100%' }} 
                        id       = "standard-basic" 
                        label    = "Descrizione" 
                        variant  = "standard"
                        value    = { dialogContent.dCategoriaCosti }
                        onChange = { (event) => setDialogContent({ ...dialogContent, dCategoriaCosti: event.target.value }) } 
                    />

                    {openDialog!=='new'&&(
                    <FormControl fullWidth variant='standard'>
                        <InputLabel id="select-excl-calc-label">Esclusione dai calcoli</InputLabel>
                        <Select
                            labelId="select-excl-calc-label"
                            id="select-excl-calc"
                            value={selectFlagsCatCosti}
                            label="Esclusione dai calcoli"
                            onChange={(event) => setSelectFlagsCatCosti(event.target.value)}
                        >
                            <MenuItem value={0}>Default</MenuItem>
                            <MenuItem value={1}>Escluso dal calcolo del DN</MenuItem>
                            <MenuItem value={2}>Escluso dal calcolo del margine e del DN</MenuItem>
                        </Select>
                    </FormControl>)}
                </DialogContent>
                <DialogActions className='flex-box-between'>
                    <Button onClick={ handleCloseDialog } autoFocus>Annulla</Button>
                    <Button onClick={ submitCategoriaCosti } disabled={ dialogContent.dCategoriaCosti === '' } variant='contained'>Conferma</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
};

export default CategorieCosti;