import React, {useContext, useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { Button
        ,FormControl
        ,FormHelperText
        ,IconButton
        ,Input
        ,InputAdornment } from '@mui/material';
import CircleProgress   from '../../../../components/CircleProgress/CircleProgress';
import { AuthContext }  from '../../../../contexts/authContext';
import { testPasswordValidator } from '../../../../utils/CommonUtilities';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { KeyIcon
       , VisibilityIcon
       , VisibilityOffIcon } from '../../../../assets/icons/icons';
import './ChangePassword.css';

function ChangePassword(props:any) {
    const auth = useContext(AuthContext);
    const urlParams = useParams(); 
    const { setPageName } = useContext(AppAuthGlobalVariables);

    const [ loading  ,setLoading  ] = useState<boolean | null>(null);

    const [ oldPassword,          setOldPassword          ] = useState("");
    const [ newPassword,          setNewPassword          ] = useState("");
    const [ confirmPassword,      setConfirmPassword      ] = useState("");
    const [ newPasswordCheck,     setNewPasswordCheck     ] = useState(true);
    const [ confirmPasswordCheck, setConfirmPasswordCheck ] = useState(true);
    const [ oldPasswordShow,      setOldPasswordShow      ] = useState(false);
    const [ newPasswordShow,      setNewPasswordShow      ] = useState(false);
    const [ confirmPasswordShow,  setConfirmPasswordShow  ] = useState(false);

    const sHelperPswAccordant = 'Password non conforme';
    const sHelperPswEqual     = 'Le due password non coincidono';
    const requiredFieldsFilled:boolean = !!(oldPassword && newPassword && confirmPassword && (newPassword !== oldPassword) && (newPassword === confirmPassword) );

    const handleCleanHall = async () => {
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setNewPasswordCheck(true);
        setConfirmPasswordCheck(true);
        setOldPasswordShow(false);
        setNewPasswordShow(false);
        setConfirmPasswordShow(false);
    }

    const changePassword = async () => {
        setLoading(true);
        try {    
            if (requiredFieldsFilled) {
                await auth.changePassword( oldPassword ,newPassword );
            }
            await handleCleanHall();
            NotificationManager.success('Cambio Password Effettuato', '', 5000);
        } catch (e:any) {
            NotificationManager.error('Procedura fallita', 'Errore', 15000);
        }
        setLoading(false);
    }

    useEffect(() => {
        setPageName?.('Cambio Password');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        setNewPasswordCheck(testPasswordValidator( newPassword ) || ( newPassword === '' ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[newPassword]);

    useEffect(()=>{
        setConfirmPasswordCheck(testPasswordValidator( confirmPassword ) || ( confirmPassword === '' ));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[confirmPassword]);

    if ( loading ) {
        return (
            <div className='component-container dettaglio-cliente'>
                <CircleProgress/>
                <div className='component-card'/>
            </div>);
    } else {
        return (
            <div className='component-container change-password'>
                <div className='component-card'>
                    <div className='actions-wrapper'>
                        <div className='wrapper-actions'>
                            <Button disabled={ !requiredFieldsFilled }variant='contained' onClick={changePassword}>Salva</Button>
                        </div>
                    </div>
                    <div className='infos-wrapper'>
                        <span className='criteriPass grey  '>Password minimo 8 caratteri, 1 maiuscolo, 1 minuscolo, 1 numero, 1 simbolo</span>
                        <FormControl className="login-credentials-wrapper full-width">
                            <Input
                                name            = "oldPassword"
                                id              = "oldPassword"
                                placeholder     = "Vecchia password"
                                value           = { oldPassword }
                                type            = { oldPasswordShow ? 'text' : 'password'}
                                onChange        = { (e:any) => { setOldPassword(e.target.value); } }
                                spellCheck      = { false }
                                startAdornment  = {
                                    <InputAdornment position="start">
                                        <KeyIcon addClass={"noHover textDark"}/>
                                    </InputAdornment>
                                }
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ e => { setOldPasswordShow(!oldPasswordShow); } }
                                        >
                                            { oldPasswordShow ? <VisibilityIcon addClass={"textDark"}/> : <VisibilityOffIcon addClass={"textDark"}/> }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText id="standard-password-helper-text" className='helperText' > </FormHelperText>
                        </FormControl>
                        <FormControl className="login-credentials-wrapper full-width">
                            <Input
                                name            = "newPassword"
                                id              = "newPassword"
                                placeholder     = "Nuova password"
                                value           = { newPassword }
                                type            = { newPasswordShow ? 'text' : 'password' }
                                onChange        = { (e:any) => { setNewPassword(e.target.value); } }
                                error           = { !newPasswordCheck }
                                spellCheck      = { false }
                                startAdornment  = {
                                    <InputAdornment position="start">
                                        <KeyIcon addClass={"noHover textDark"}/>
                                    </InputAdornment>
                                }
                                endAdornment    = {
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ e => { setNewPasswordShow(!newPasswordShow); } }
                                        >
                                            { newPasswordShow ? <VisibilityIcon addClass={"textDark"}/> : <VisibilityOffIcon addClass={"textDark"}/> }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText id="standard-password-helper-text2" className='helperText' >{ newPassword && !newPasswordCheck ? sHelperPswAccordant : ' ' }</FormHelperText>
                        </FormControl>
                        <FormControl className="login-credentials-wrapper full-width">
                            <Input
                                name            = "confirmPassword"
                                id              = "confirmPassword"
                                placeholder     = "Conferma password"
                                value           = { confirmPassword }
                                type            = { confirmPasswordShow ? 'text' : 'password' }
                                onChange        = { (e:any) => { setConfirmPassword(e.target.value); } }
                                error           = { !(confirmPasswordCheck && ( confirmPassword === newPassword )) }
                                spellCheck      = { false }
                                startAdornment  = {
                                    <InputAdornment position="start">
                                        <KeyIcon addClass={"noHover textDark"}/>
                                    </InputAdornment>
                                }
                                endAdornment    = {
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={ e => { setConfirmPasswordShow(!confirmPasswordShow); } }
                                        >
                                            { confirmPasswordShow ? <VisibilityIcon addClass={"textDark"}/> : <VisibilityOffIcon addClass={"textDark"}/> }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText id="standard-password-helper-text3" className='helperText' >
                                                { 
                                                    ( confirmPassword && !confirmPasswordCheck ) ? 
                                                        sHelperPswAccordant 
                                                        : (confirmPassword && ( confirmPassword !== newPassword ) ) ?
                                                            sHelperPswEqual
                                                            : ' ' }</FormHelperText>
                        </FormControl>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangePassword