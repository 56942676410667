const environment = {
    dev: {
        //API_URL               : 'https://doomentertainment.api.systrategy.com/doom-dev',
        API_URL               : 'https://backend.businessmanager.doomentertainment.systrategy.com/businessmanager',
        ATTACHMENTS_URL       : 'https://businessmanager-attachments.doomentertainment.systrategy.com',
        ENV                   : 'dev',
        REACT_APP_USERPOOL_ID : 'eu-central-1_aWdHuXuce',
        REACT_APP_CLIENT_ID   : '2i0r1r5lce7fmd5g5uq3mdhiep',
        FORCE_MFA_TOTP        : true,
        CHECK_REFRESH_TOKEN   : 60000,
        REFRESH_TOKEN_INTERVAL: 1680000,
        DEFAULT_TIMEOUT_POPUPS: 5000,
        DAY_IN_SECONDS        : 86400
    },
    test: {
        API_URL               : 'ginetto',
        ATTACHMENTS_URL       : 'https://businessmanager-attachments.doomentertainment.systrategy.com',
        ENV                   : 'test',
        REACT_APP_USERPOOL_ID : '',
        REACT_APP_CLIENT_ID   : '',
        FORCE_MFA_TOTP        : true,
        CHECK_REFRESH_TOKEN   : 60000,
        REFRESH_TOKEN_INTERVAL: 3600000,
        DEFAULT_TIMEOUT_POPUPS: 5000,
        DAY_IN_SECONDS        : 86400
    },
    prod: {
        API_URL               : 'https://doomentertainment.api.systrategy.com/doom-dev',
        ATTACHMENTS_URL       : 'https://businessmanager-attachments.doomentertainment.systrategy.com',
        ENV                   : 'prod',
        REACT_APP_USERPOOL_ID : '',
        REACT_APP_CLIENT_ID   : '',
        FORCE_MFA_TOTP        : true,
        CHECK_REFRESH_TOKEN   : 60000,
        REFRESH_TOKEN_INTERVAL: 3600000,
        DEFAULT_TIMEOUT_POPUPS: 5000,
        DAY_IN_SECONDS        : 86400
    }
}

export default environment[process.env.REACT_APP_ENV || 'dev'];