import React from 'react';
import TextField from '@mui/material/TextField';

function Logs(props:any) {
	return (
		<div className='proposta-logs'>
			<div className='logs-wrapper'>
				<div className='flex-row'>
					<TextField
						id                   = "standard-basic"
						label                = "Data Ultima Modifica"
						variant              = "standard"
						value                = {props.lastEditDate}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
					<TextField
						id                   = "standard-basic"
						label                = "Ultima Modifica Effettuata Da"
						variant              = "standard"
						value                = {props.lastModMadeBy}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
					<TextField
						id                   = "standard-basic"
						label                = "Creata Da"
						variant              = "standard"
						value                = {props.createdBy}
						inputProps           = {{maxLength: 50}}
						disabled
					/>
				</div>
			</div>
		</div>
	)
}

export default Logs