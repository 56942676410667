import React, {useContext, useEffect, useState} from 'react';
import './Dashboard.css';
import { AppAuthGlobalVariables } from '../../../contexts/AppAuthContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../../../contexts/authContext';
import config from '../../../config';
import { NotificationManager } from 'react-notifications';
import Graph from '../../../utils/Graph';
import CircleProgress from '../../../components/CircleProgress/CircleProgress';
import { formatNumbersIt } from '../../../utils/CommonUtilities';
import TextField from '@mui/material/TextField';
import { PercentageIcon } from '../../../assets/icons/icons';

const arrMonths = ['GEN', 'FEB', 'MAR', 'APR', 'MAG', 'GIU', 'LUG', 'AGO', 'SET', 'OTT', 'NOV', 'DIC']

const tooltipMaker = (data:any) => {
	let descrizioneSplit = data.label.split(' - ');
	return (
		<div style={{
				background: "white",
				color: "inherit",
				fontSize: "13px",
				borderRadius: "2px",
				boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
				padding: "5px 9px"
			}}
		>
			<div style={{
					whiteSpace: "pre",
					display: "flex",
					alignItems: "center"
				}}
			>
				{
					data.data.GRAFICO==="RICAVI"
					?
						data.data.ETICHETTA==="FATTURATO" || data.data.ETICHETTA==="DA FATTURARE" 
						?
							<>
								<span style={{display: "block", width: "12px", height: "12px", background: `${data.color}`, marginRight: "7px"}}></span>
								<span>{descrizioneSplit[0].split('_')[0] + ' - ' + descrizioneSplit[1]}: <strong>{formatNumbersIt(data.value)}</strong></span>
							</>
						:
							<div className='first-graph-stacked-cols'>
								{data.data.IMPORTO_FATT_DIR&&(
									<div className='first-graph-stacked-cols-element'>
										<span style={{display: "block", width: "12px", height: "12px", background: `${data.data.IMPORTO_FATT_DIRColor}`, marginRight: "7px"}}></span>
										<span>FATTURAZIONE DIRETTA TALENT - {descrizioneSplit[1]}: <strong>{formatNumbersIt(data.data.IMPORTO_FATT_DIR)}</strong></span>
									</div>
								)}
								<div className='first-graph-stacked-cols-element'>
									<span style={{display: "block", width: "12px", height: "12px", background: `${data.data.IMPORTO_NO_FATT_DIRColor}`, marginRight: "7px"}}></span>
									<span>{descrizioneSplit[0].split('_')[0] + ' - ' + descrizioneSplit[1]}: <strong>{formatNumbersIt(data.data.IMPORTO_NO_FATT_DIR)}</strong></span>
								</div>
							</div>
					:
						<>
							<span style={{display: "block", width: "12px", height: "12px", background: `${data.color}`, marginRight: "7px"}}></span>
							<span>{descrizioneSplit[0].replace('_', ' ') + ' - ' + descrizioneSplit[1]}: <strong>{formatNumbersIt(data.value)}</strong></span>
						</>
				}
				
			</div>
		</div>
	)
}

const tooltipMakerPie = ({datum}:any) => {
	return (
		<div style={{
				background: "white",
				color: "inherit",
				fontSize: "13px",
				borderRadius: "2px",
				boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
				padding: "5px 9px"
			}}
		>
			<div style={{
					whiteSpace: "pre",
					display: "flex",
					alignItems: "center"
				}}
			>
				<span style={{display: "block", width: "12px", height: "12px", background: `${datum.color}`, marginRight: "7px"}}></span>
				<span>{datum.label}: <strong>{formatNumbersIt(datum.value)}%</strong></span>
			</div>
		</div>
	)
}

function Dashboard(props:any) {

	const { setPageName } = useContext(AppAuthGlobalVariables);
	const auth = useContext(AuthContext);
	const urlParams = useParams();

	const [margineDoomNettoDn, setMargineDoomNettoDn] = useState('');
	const [margineDoomEffettivo, setMargineDoomEffettivo] = useState('');
	const [firstGraphLoading, setFirstGraphLoading] = useState(false);
	const [secondGraphLoading, setSecondGraphLoading] = useState(false);
	const [thirdGraphLoading, setThirdGraphLoading] = useState(false);
	const [fourthGraphLoading, setFourthGraphLoading] = useState(false);
	const [firstData, setFirstData] = useState([]);
	const [secondData, setSecondData] = useState([]);
	const [thirdData, setThirdData] = useState([]);
	const [fourthData, setFourthData] = useState([]);

	useEffect(() => {
		async function init(){
			setPageName?.(urlParams['*']);
			await getGraphsData();
		}
		init();
	},[])

	const getGraphsData = async() => {
		try{
			let axiosParams = {
				url: 'graphs',
				method: 'get',
				baseURL: config.API_URL,
				headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`}
			}
  
        	let response = await axios(axiosParams);

			if(response.status === 200){
				//setRows(response.data);
				let annoAttuale = new Date().getFullYear();

				const data = response.data;
				let firstGraphFiltered = data.filter((el:any) => el.GRAFICO==='RICAVI');

				//let secondGraphFiltered = data.filter((el:any) => el.GRAFICO==="ANDAMENTO");
				let actualSecondGraph = data.filter((el:any) => el.GRAFICO==="ANDAMENTO"&&el.ANNO===annoAttuale);
				let prevYearSecondGraph = data.filter((el:any) => el.GRAFICO==="ANDAMENTO"&&el.ANNO===annoAttuale-1);

				let thirdGraphFiltered = data.filter((el:any) => el.GRAFICO==="REDEMPTION");
				let actualFourthGraph = data.filter((el:any) => el.GRAFICO==="TOP"&&el.ANNO===annoAttuale);
				let prevYearFourthGraph = data.filter((el:any) => el.GRAFICO==="TOP"&&el.ANNO===annoAttuale-1);
				//let fourthGraphFiltered = data.filter((el:any) => el.GRAFICO==="TOP");

				let marginsLabels = data.filter((el:any) => el.GRAFICO==="MARGINI");

				//manipolazione del primo grafico per colonne extra
				let mergedFirstGraphColsPerFlagFattTalent: any = [];
				for (let index = 0; index < firstGraphFiltered.length; index++) {
					const element = firstGraphFiltered[index];

					if(element.ETICHETTA === "DA FATTURARE" || element.ETICHETTA === "FATTURATO"){
						element.IMPORTO_NO_FATT_DIR = element.IMPORTO;
						mergedFirstGraphColsPerFlagFattTalent.push(element);
						continue;
					}

					let alreadyPresentInMerged = mergedFirstGraphColsPerFlagFattTalent.find((item:any) => item.ETICHETTA === element.ETICHETTA);
					if(!alreadyPresentInMerged){
						let oppostoFlag = firstGraphFiltered.find((item:any) => item.ETICHETTA === element.ETICHETTA && item.FLAG_FATT_DIR_TALENT !== element.FLAG_FATT_DIR_TALENT);
						if(oppostoFlag){
							if(element.FLAG_FATT_DIR_TALENT === 'Y'){
								element.IMPORTO_FATT_DIR = element.IMPORTO ? element.IMPORTO : null;
								element.IMPORTO_NO_FATT_DIR = oppostoFlag.IMPORTO ? oppostoFlag.IMPORTO : null;
							}else if(element.FLAG_FATT_DIR_TALENT === 'N'){
								element.IMPORTO_FATT_DIR = oppostoFlag.IMPORTO ? oppostoFlag.IMPORTO : null;
								element.IMPORTO_NO_FATT_DIR = element.IMPORTO ? element.IMPORTO : null;
							}
							mergedFirstGraphColsPerFlagFattTalent.push(element);
						}else{
							//se non trovo l'oppost flag fatt dir talent vuol dire che non trovo il dato e quindi lo aggiungo
							if(element.FLAG_FATT_DIR_TALENT === 'Y'){
								element.IMPORTO_FATT_DIR = element.IMPORTO;
							}else if(element.FLAG_FATT_DIR_TALENT === 'N'){
								element.IMPORTO_NO_FATT_DIR = element.IMPORTO;
							}
							mergedFirstGraphColsPerFlagFattTalent.push(element);
						}
					}else{
						continue;
					}
				}

				let reorderedFirstGraph:any = [{},{},{},{},{}];
				for (let index = 0; index < mergedFirstGraphColsPerFlagFattTalent.length; index++) {
					const element = mergedFirstGraphColsPerFlagFattTalent[index];
					
					switch (element.ETICHETTA) {
						case 'LEAD':
							//element.color = '#cbe1f2';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#A2B4C1'
							element["IMPORTO_FATT_DIRColor"] = '#cbe1f2'
							reorderedFirstGraph[0] = element;
							break;
						case 'TOTALE':
							//element.color = '#FF9D60';
							element["IMPORTO_NO_FATT_DIRColor"] = '#FF9D60'
							element["IMPORTO_FATT_DIRColor"] = '#FFCEB0'
							reorderedFirstGraph[1] = element;
						break;
						case 'RIFIUTATE':
							//element.color = '#F78E9C';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#F78E9C'
							element["IMPORTO_FATT_DIRColor"] = '#ed6b7c'
							reorderedFirstGraph[2] = element;
						break;
						case 'FATTURATO':
							//element.color = '#CA85AC';//'#bbfbb1';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#CA85AC'
							element["IMPORTO_FATT_DIRColor"] = '#CA85AC'
							reorderedFirstGraph[3] = element;
						break;
						case 'DA FATTURARE':
							//element.color = '#E1B4CD';//'#fbf1b1';
							element["IMPORTO_NO_FATT_DIRColor"] =  '#E1B4CD'
							element["IMPORTO_FATT_DIRColor"] = '#E1B4CD'
							reorderedFirstGraph[4] = element;
						break;
					
						default:
							break;
					}
				}

				for (let index = 0; index < actualSecondGraph.length; index++) {
					const element = actualSecondGraph[index];

					let foundPrevYearMatch = prevYearSecondGraph.find((el:any) => el.MESE === element.MESE);
					if(foundPrevYearMatch){
						element.PREVIOUS_YEAR = foundPrevYearMatch.IMPORTO;
					}

					element.MESE_IN_LETTERE = arrMonths[element.MESE];
					element.ACTUAL = element.IMPORTO;
				}
				//ciclo sui prev e mi salvo quelli non trovati con coincidenza nell'anno attuale
				let noMatchPrevYear = [];
				for (let index = 0; index < prevYearSecondGraph.length; index++) {
					const element = prevYearSecondGraph[index];

					let foundActualYearMatch = actualSecondGraph.find((el:any) => el.MESE === element.MESE);
					if(!foundActualYearMatch){
						element.PREVIOUS_YEAR = element.IMPORTO;
						element.ACTUAL = 0;
						element.MESE_IN_LETTERE = arrMonths[element.MESE];
						noMatchPrevYear.push(element);
					}
				}
				let sortedSecondGraph:any = [...noMatchPrevYear,...actualSecondGraph].sort((a:any, b:any) => a.MESE > b.MESE ? 1 : a.MESE < b.MESE ? -1 : 0 );

				for (let index = 0; index < thirdGraphFiltered.length; index++) {
					let element:any = thirdGraphFiltered[index];
					thirdGraphFiltered[index] = {
						"id": element.ETICHETTA,
						"label": element.ETICHETTA,
						"value": element.IMPORTO,
						"color": (() => {
							switch (element.ETICHETTA) {
								case "LEAD":
									return "#cbe1f2";
								case "CONFERMATE":
									return "#fbf1b1";
								case "CONTRATTUALIZZATE":
									return "#bbfbb1";
								case "RIFIUTATE":
									return "#F78E9C";
							}
						})()
					}
				}
				
				for (let index = 0; index < actualFourthGraph.length; index++) {
					const element = actualFourthGraph[index];
					element.ACTUAL = element.IMPORTO;

					let foundPrevYearMatch = prevYearFourthGraph.find((el:any) => el.ETICHETTA === element.ETICHETTA);
					if(foundPrevYearMatch){
						element.PREVIOUS_YEAR = foundPrevYearMatch.IMPORTO;
					}
				}
				actualFourthGraph.reverse();

				setFirstData(reorderedFirstGraph);
				setSecondData(sortedSecondGraph);
				setThirdData(thirdGraphFiltered);
				setFourthData(actualFourthGraph);
				setMargineDoomNettoDn(formatNumbersIt(marginsLabels.find((marg:any) => marg.ETICHETTA === 'AVG_MARG_DOOM_DN').IMPORTO));
				setMargineDoomEffettivo(formatNumbersIt(marginsLabels.find((marg:any) => marg.ETICHETTA === 'AVG_MARG_DOOM_FATT').IMPORTO));

				setFirstGraphLoading(false);
				setSecondGraphLoading(false);
				setThirdGraphLoading(false);
				setFourthGraphLoading(false);
			}
		}catch (e:any){
			//setLoading(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
	}

	return (
		<div className='component-container dashboard'>
			<div className='component-card'>
				<div className='graph-wrapper'>
					{firstGraphLoading ?
						<CircleProgress/>
					:
						<Graph
							graphType='bar'
							data={firstData}
							indexBy='ETICHETTA'
							layout='vertical'
							groupMode='stacked'
							keys={['IMPORTO_FATT_DIR', 'IMPORTO_NO_FATT_DIR']}
							margin={{ top: 20, right: 0, bottom: 60, left: 80 }}
							padding={0.3}
							maxValue={'auto'}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'STATUS',
								legendPosition: 'start',
								legendOffset: 36,
								truncateTickAt: 0
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'RICAVI',
								legendPosition: 'middle',
								legendOffset: -70,
								truncateTickAt: 0,
								format: (s:any) => {
									let value = parseInt(s);
									return formatNumbersIt(value);
								}
							}}
							label={(s:any) => formatNumbersIt(s.value)}
							colorFromColumnData={true}
							colorBy="id"
							tooltip={(el:any) => tooltipMaker(el)}
							showLegend={false}
						/>
					}
				</div>
				<div className='graph-wrapper'>
					{secondGraphLoading ?
						<CircleProgress/>
					:
						<Graph
							graphType='bar'
							data={secondData}
							indexBy='MESE_IN_LETTERE'
							layout='vertical'
							groupMode='grouped'
							keys={['ACTUAL','PREVIOUS_YEAR']}
							margin={{ top: 20, right: 0, bottom: 60, left: 80 }}
							padding={0.3}
							maxValue={'auto'}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'MESE',
								legendPosition: 'middle',
								legendOffset: 36,
								truncateTickAt: 0
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'CONTRATTUALIZZATE + CHIUSE',
								legendPosition: 'middle',
								legendOffset: -65,
								truncateTickAt: 0,
								format: (s:any) => {
									let value = parseInt(s);
									return formatNumbersIt(value);
								}
							}}
							label={(s:any) => formatNumbersIt(s.value)}
							colorFromColumnData={["#FA8CCA","#FDBDE1"]}
							tooltip={(el:any) => tooltipMaker(el)}
							showLegend={false}
						/>
					}
				</div>
				<div className='graph-wrapper with-margz'>
					<div className='margz-wrap'>
						<TextField 
							id="standard-basic" 
							variant="outlined" 
							title='Margine Doom Medio Al Lordo Del DN' 
							label="Margine Doom Medio Al Lordo Del DN" 
							value={margineDoomNettoDn} 
							inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}}  
							InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} 
							//sx={{width: '50%'}}
						/>
						<TextField 
							id="standard-basic" 
							variant="outlined" 
							title='Margine Doom Medio Effettivo Fatturato' 
							label="Margine Doom Medio Effettivo Fatturato" 
							value={margineDoomEffettivo} 
							inputProps={{readOnly: true,sx:{textAlign:'right'}, className:'resized-pad-text-field'}}  
							InputProps = {{ endAdornment: <PercentageIcon addClass='pad-left'/> }} 
							//sx={{width: '50%'}}
						/>
					</div>
					{thirdGraphLoading ?
						<CircleProgress/>
					:
						<Graph
							graphType='pie'
							data={thirdData}
							margin={{ top: 20, right: 80, bottom: 40, left: 80 }}
							innerRadius={0.5}
							padAngle={0.7}
							cornerRadius={3}
							tooltip={(el:any)=>tooltipMakerPie(el)}
						/>
					}
				</div>
				<div className='graph-wrapper'>
					{fourthGraphLoading ?
						<CircleProgress/>
					:
						<Graph
							graphType='bar'
							data={fourthData}
							indexBy='ETICHETTA'
							layout='horizontal'
							groupMode='grouped'
							keys={['ACTUAL','PREVIOUS_YEAR']}
							margin={{ top: 50, right: 0, bottom: 50, left: 150 }}
							padding={0.3}
							maxValue={'auto'}
							axisBottom={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: -35,
								legend: 'CONFERMATE + CONTRATTUALIZZATE + CHIUSE',
								legendPosition: 'middle',
								legendOffset: 45,
								truncateTickAt: 0,
								format: (s:any) => {
									let value = parseInt(s);
									return formatNumbersIt(value);
								}
							}}
							axisLeft={{
								tickSize: 5,
								tickPadding: 5,
								tickRotation: 0,
								legend: 'TOP',
								legendPosition: 'middle',
								legendOffset: -125,
								truncateTickAt: 0,
								format: (s:any) => {
									const separator = '●';
									let MAXLABELLENGTH = 18;
									let nPieceLength   = ~~( ( MAXLABELLENGTH - 3 ) / 2 );
									const s1 = ( ( s === 0 ? 0 : ( s || '' ) ) + '' );
									const s2 = s1.replaceAll(separator, separator.trim() ); 
									return ( s1.length < MAXLABELLENGTH ) ? s1 : ( s2.slice(0,nPieceLength) + '...' + s2.slice(-nPieceLength) );
								}
							}}
							label={(s:any) => formatNumbersIt(s.value)}
							colorFromColumnData={["#FF9D60","#FFCEB0"]}
							tooltip={(el:any) => tooltipMaker(el)}
							showLegend={false}
						/>
					} 
				</div>
			</div>
		</div>
	)
}

export default Dashboard;