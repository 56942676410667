import React from 'react';
import './icons.css';

export const AccountCircleIcon          = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-circle-user ${                addClass || '' }` } />; 

export const AccountCircleSolidIcon     = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-circle-user ${                  addClass || '' }` } />;

export const ActiveIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-circle-check ${                 addClass || '' }` } />;

export const AddCircleOutlineIcon       = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-circle-plus ${       addClass || '' }` } />;

export const AddIcon                    = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-plus-large ${                   addClass || '' }` } />;

export const AgencyIcon                 = ({ addClass }: { addClass?: string }) => <i className={` icon fa-solid fa-building-user ${                addClass || '' }` } />;

export const AgencyLightIcon            = ({ addClass }: { addClass?: string }) => <i className={` icon fa-light fa-building-user ${                addClass || '' }` } />;

export const AlternateEmailIcon         = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-at ${                         addClass || '' }` } />;

export const AreaIcon                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-clone ${                        addClass || '' }` } />;

export const AreaLightIcon              = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-clone ${                        addClass || '' }` } />;

export const ArrowLeft                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-solid fa-chevron-left ${        addClass || '' }` } />;

export const ArrowRight                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-solid fa-chevron-right ${       addClass || '' }` } />;    

export const ArrowDown                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-chevron-down ${               addClass || '' }` } />;    

export const CabinetIcon                = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-cabinet-filing ${               addClass || '' }` } />;

export const CachedIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-rotate ${                     addClass || '' }` } />;
    
export const CancelPresentationIcon     = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-rectangle-xmark ${            addClass || '' }` } />;

export const CategoriesIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-folders ${                      addClass || '' }` } />;

export const CategoriesCostiIcon        = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-solid-folders-circle-dollar ${    addClass || '' }` } />;

export const CategoriesCostiLightIcon   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-light-folders-circle-dollar ${    addClass || '' }` } />;

export const CategoriesMerceologicheIcon= ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-solid-folders-tag ${              addClass || '' }` } />;

export const CategorieMercLightIcon     = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-light-folders-tag ${              addClass || '' }` } />;

export const CheckBoxEmptyIcon          = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-square ${                     addClass || '' }` } />;

export const CheckBoxFilledIcon         = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-square-check ${                 addClass || '' }` } />;

export const CheckIcon                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-check ${                      addClass || '' }` } />;

export const ClientIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-user-tie     ${                 addClass || '' }` } />;

export const ClientLightIcon            = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-user-tie     ${                 addClass || '' }` } />;

export const CopyIcon                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-copy         ${                 addClass || '' }` } />;

export const CreditCardIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-credit-card ${                addClass || '' }` } />;
    
export const DashBoardIcon              = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-solid fa-gauge ${             addClass || '' }` } />;

export const DashBoardLightIcon         = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-gauge ${                        addClass || '' }` } />;

export const DisabledIcon               = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-solid-circle-check-slash ${       addClass || '' }` } />;

export const DoDisturbIcon              = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-ban ${                        addClass || '' }` } />;
    
export const DoNotDisturbOnOutlinedIcon = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-circle-minus ${      addClass || '' }` } />;

export const DoneAllIcon                = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-solid fa-check-double ${        addClass || '' }` } />;
    
export const DoubleArrowLeftIcon        = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-angles-left ${       addClass || '' }` } />;
    
export const DoubleArrowRightIcon       = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-angles-right ${      addClass || '' }` } />;

export const DownLoadIcon               = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-file-arrow-down ${            addClass || '' }` } />;
    
export const EditIcon                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-pen ${                          addClass || '' }` } />;
//export const EditIcon                   = React.forwardRef((props, ref) => <i {...props} ref={ref} className={ `icon fa-solid fa-pen ${                          props.addClass || '' }` } />);
export const EuroIcon                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-euro-sign      ${               addClass || '' }` } />;    

export const FolderOffIcon              = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-folder-xmark ${               addClass || '' }` } />;

export const FilterIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-filter       ${               addClass || '' }` } />;
    
export const ForecastIcon               = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-chart-mixed ${                  addClass || '' }` } />;
    
export const GearIcon                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-solid fa-gear ${                addClass || '' }` } />;

export const GroupIcon                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-users ${                        addClass || '' }` } />;

export const HourglassIcon              = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-thin fa-hourglass ${                     addClass || '' }` } />;

export const KeyIcon                    = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-key-skeleton ${               addClass || '' }` } />;

export const ImpersonateIcon            = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-user-secret ${                  addClass || '' }` } />; 

export const ImpersonateIconEnd         = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-solid-user-secret-slash ${        addClass || '' }` } />; 

export const LeaderboardIcon            = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-solid fa-chart-simple ${        addClass || '' }` } />;

export const LocationIcon               = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-map-location-dot ${             addClass || '' }` } />;

export const LocationLightIcon          = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-map-location-dot ${             addClass || '' }` } />;

export const LogoutIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-right-from-bracket ${           addClass || '' }` } />;

export const MultipleIcon               = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-rectangle-history ${ addClass || '' }` } />;

export const NoteEdit                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-pen-to-square ${              addClass || '' }` } />;

export const NoteNuovo                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-note ${                         addClass || '' }` } />;

export const NotificationIcon           = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-bell ${                         addClass || '' }` } />;

export const NotificationIconSolid      = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-bell ${                         addClass || '' }` } />;

export const NotificationImportantIcon  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-bell-exclamation ${             addClass || '' }` } />;

export const OrderIcon                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-sheet-plastic ${                addClass || '' }` } />;

export const OrderLightIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-sheet-plastic ${                addClass || '' }` } />;

export const OtterIcon                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-otter ${                      addClass || '' }` } />;

export const PercentageIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-percent ${                    addClass || '' }` } />;

export const PinIcon                    = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-input-numeric ${     addClass || '' }` } />;

export const ProductIcon                = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-box ${                          addClass || '' }` } />;

export const ProductLightIcon           = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-box ${                          addClass || '' }` } />;

export const ReferenteIcon              = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-user-helmet-safety ${           addClass || '' }` } />;

export const ReferenteLightIcon         = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-user-helmet-safety ${           addClass || '' }` } />;

export const ReferenteIcon2             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-person-sign ${                  addClass || '' }` } />;

export const ReportIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-thin fa-file-chart-column ${             addClass || '' }` } />;

export const SaveIcon                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-floppy-disk ${                  addClass || '' }` } />;

export const SaveOutlinedIcon           = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-floppy-disk ${                addClass || '' }` } />;

export const ScadenzarioIcon           = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-light-calendar-circle-dollar ${   addClass || '' }` } />;

export const SearchIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-magnifying-glass ${  addClass || '' }` } />;
    
export const SsidChartIcon              = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-chart-line-up ${              addClass || '' }` } />;
    
export const SupervisorAccountIcon      = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-crown ${                      addClass || '' }` } />;

export const NotSupervisorAccountIcon   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-regular-crown-slash ${            addClass || '' }` } />;

export const TableChartIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-table-columns ${              addClass || '' }` } />;
   
export const TalentIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-solid-user-sparkle  ${            addClass || '' }` } />;

export const TalentLightIcon            = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-kit fa-light-user-sparkle  ${            addClass || '' }` } />;

export const ThreeVerticalDot           = ({ addClass, onClick }: { addClass?: string, onClick?:any }) => <i className={ `icon fa-regular fa-ellipsis-vertical ${          addClass || '' }` } onClick={onClick}/>;

export const TrashIcon                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-trash ${                        addClass || '' }` } />;
    
export const TrendingDownIcon           = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-arrow-trend-down ${             addClass || '' }` } />;
    
export const TrendingUpIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-arrow-trend-up ${               addClass || '' }` } />;

export const UploadIcon                 = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-solid fa-up-from-line ${        addClass || '' }` } />;
    
export const UserIcon                   = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-solid fa-user ${                         addClass || '' }` } />;
    
export const UsersLightIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-user-group ${                   addClass || '' }` } />;

export const UsersIcon                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-light fa-user-group ${                   addClass || '' }` } />;

export const VisibilityIcon             = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-eye ${                        addClass || '' }` } />;
    
export const VisibilityOffIcon          = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-eye-slash ${                  addClass || '' }` } />;

export const XIcon                      = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-regular fa-x ${                          addClass || '' }` } />;

export const XMarkIcon                  = ({ addClass }: { addClass?: string }) => <i className={ `icon fa-sharp fa-regular fa-xmark ${             addClass || '' }` } />;