import React, {useState, useEffect, useContext} from 'react';
import CircleProgress from '../../../../components/CircleProgress/CircleProgress';
import NoRowsDataGridComponent from '../../../../components/NoRowsDataGridComponent/NoRowsDataGridComponent';
import config from '../../../../config';
import './Scadenzario.css';
import axios from 'axios';
import { AuthContext } from '../../../../contexts/authContext';
import { NotificationManager } from 'react-notifications';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { 
    DataGridPremium,
    LicenseInfo,
    GridColDef,
    GridRowModel,
    GridToolbarContainer,
    GridToolbarExport
} from '@mui/x-data-grid-premium';
import { AppAuthGlobalVariables } from '../../../../contexts/AppAuthContext';
import { formatNumbersIt, getFormatDate, getFormatDateFromDate } from '../../../../utils/CommonUtilities';
import { CheckBoxEmptyIcon, CheckBoxFilledIcon, EditIcon } from '../../../../assets/icons/icons';
import { useNavigate, useParams } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

const months:{id:string, label:string}[] = [
    {id:'01', label:'Gennaio'},
    {id:'02', label:'Febbraio'},
    {id:'03', label:'Marzo'},
    {id:'04', label:'Aprile'},
    {id:'05', label:'Maggio'},
    {id:'06', label:'Giugno'},
    {id:'07', label:'Luglio'},
    {id:'08', label:'Agosto'},
    {id:'09', label:'Settembre'},
    {id:'10', label:'Ottobre'},
    {id:'11', label:'Novembre'},
    {id:'12', label:'Dicembre'},
]

function CustomToolbar() {
    const { pageName } = useContext(AppAuthGlobalVariables);
	return (
		<GridToolbarContainer>
			<GridToolbarExport
                excelOptions={{
                    fileName: `${pageName?.toLowerCase()} - ${getFormatDateFromDate(new Date(), '_')}`
                }}
            />
		</GridToolbarContainer>
	);
}

function Scadenzario(props:any) {
    
    const auth = useContext(AuthContext);
    const { userEmail, changePageName } = useContext(AppAuthGlobalVariables);
    const urlParams = useParams();

    const navigate = useNavigate();

    const [ loading  , setLoading  ] = useState(true);
    const [rows, setRows] = useState<GridRowModel[]>([]);
    const [talentManagers, setTalentManagers] = useState([]);
    const [anno, setAnno] = useState(new Date().getFullYear().toString());
    const [beginPeriod, setBeginPeriod] = useState('01');
    const [endPeriod, setEndPeriod] = useState(((new Date().getMonth()+1)).toString().padStart(2, '0'));
    const [kTalentManager, setKTalentManager] = useState<any>(' ');

    const userIsAdmin = localStorage.getItem('admin') === 'Y' ? true : false;
    const userIsTalentManager = localStorage.getItem('talent_manager') === 'Y' ? true : false;
    const userIsAmministrazione = localStorage.getItem('amministrazione') === 'Y' ? true : false;

    const ActiveCell:any = ( params:any ) => {
        //per la riga 0 dei total
        if(params.id === 0){
            return null 
        }else{
            return ( !params.value || params.value === 'N'
                ? <div className='flexed cursor-pointer' onClick={(clickEvent) => {modRowFlag('Y', params)}}><CheckBoxEmptyIcon addClass='font-22'/></div>
                : <div className='flexed cursor-pointer' onClick={(clickEvent) => {modRowFlag('N', params)}}><CheckBoxFilledIcon addClass='font-22'/></div>
            )
        }
    }
    
    const ActionCell:any = (params:any) => {
        return !params.id ? null : <IconButton onClick={()=>{navigate(`/proposta/${params.row.KPROPOSTA}`)}}><EditIcon addClass='font-16'/></IconButton>
    }

    const columns:GridColDef[] = [
        { field: 'KPROPOSTA', headerClassName: 'header-cell propostaCod', headerName: "Id", type: 'text', width: 75, editable: false, disableColumnMenu: true, align: 'center', headerAlign: 'center'},
        { field: 'DCLIENTE', headerClassName: 'header-cell clienteDesc', headerName: "Cliente", type: 'text', width: 300, editable: false},
        { field: 'DPROPOSTA', headerClassName: 'header-cell propostaDesc', headerName: "Proposta", type: 'text', width: 300, editable: false},
        { field: 'DINTEST_FATT', headerClassName: 'header-cell intestatarioFatturaDesc', headerName: "Intestatario Fattura", type: 'text', width: 100, editable: false},
        { field: 'DREF_COMM', headerClassName: 'header-cell referenteCommercialeDesc', headerName: "Referente Commerciale", type: 'text', width: 100, editable: false},
        { field: 'DTALENT_MANAGER', headerClassName: 'header-cell talentManagerDesc', headerName: "Talent Manager", type: 'text', width: 100, editable: false},
        { field: 'DATA_FATTURAZIONE', headerClassName: 'header-cell dataFatturazione', headerName: "Data Emissione", width: 100, editable: true, align: 'center', headerAlign: 'center', valueFormatter: (params) => {return getFormatDate(params.value)}},
        { field: 'IMPORTO', headerClassName: 'header-cell importo', headerName: "In Fatturazione", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
        { field: 'IMPORTO_CONFERMATO', headerClassName: 'header-cell importoConfermaAttivita', headerName: "€ Conferma Attività Talent", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
        { field: 'IMPORTO_FATTURATO', headerClassName: 'header-cell importoFatturato', headerName: "€ Fatturato", type: 'text', width: 100, editable: false, align: 'right', headerAlign: 'right', valueFormatter: (params) => {return (params.value || params.value === 0) ? formatNumbersIt(params.value) + ' €' : '0 €'}},
        { field: 'FLAG_CONFERMA_ATT', headerClassName: 'header-cell flagConfermaAttivita', headerName: "Conferma Attività Talent", type: 'text', width: 100, editable: false, align: 'center', headerAlign: 'center', renderCell: (params:any) => ActiveCell(params)},
        { field: 'FLAG_FATTURATO', headerClassName: 'header-cell flagFatturato', headerName: "Fatturato", type: 'text', width: 100, editable: false, align: 'center', headerAlign: 'center', renderCell: (params:any) => ActiveCell(params)},
        { field: 'NOTE', headerClassName: 'header-cell note', headerName: "Note", type: 'text', width: 200, editable: false},
        { field: 'ACTION', headerClassName: 'header-cell action', headerName: "", type: 'text', width: 60, editable: false, sortable: false, headerAlign: 'center', align: 'center', filterable: false,
            renderCell: (params:any) => ActionCell(params),
            disableColumnMenu: true,
            disableExport: true
        }
        /* { field: 'END_DATE', headerClassName: 'header-cell endDate', headerName: "Data Fine", type: 'text', width: 100, editable: false, valueFormatter: (params) => getFormatDate(params.value)}, */
        /* { field: 'USER_INS', headerClassName: 'header-cell userInsDesc', headerName: "Inserito Da", type: 'text', width: 100, editable: false}, */
    ];

    const hiddenCols = {
        FLAG_CONFERMA_ATT: userIsTalentManager || userIsAdmin,
        FLAG_FATTURATO: userIsAmministrazione || userIsAdmin
    };

    useEffect(() => {
        changePageName?.(urlParams['*']);
    },[])

    useEffect(() => {
        async function init(){
            if(userEmail){
                await getUtenti();
            }
        }
        init();
    },[userEmail]);
   
    useEffect(() => {
        async function init(){
            if(talentManagers.length!==0){
                if(anno.length===4){
                    await getScadenzario();
                }
            }
        }
        init();
    },[kTalentManager, endPeriod, beginPeriod, anno, talentManagers]);

    const getUtenti = async() => {
        try {    
			let axiosParams = {
				url		: 'users',
				method	: 'get',
				baseURL	: config.API_URL,
				headers	: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`}
			}
			let response = await axios(axiosParams);

			if (response.status === 200){
                let talentManagers = response.data.filter((tm:any) => tm.FLAG_TALENT_MANAGER === 'Y');

                if(localStorage.getItem('talent_manager')==='Y'){
                    let utenteCorrente = talentManagers.find((tm:any) => tm.EMAIL_ADDRESS === userEmail);
                    if(utenteCorrente){
                        setKTalentManager(utenteCorrente.id);
                    }
                }else{
                    //se l'utente non è talent manager seleziona tutti
                    setKTalentManager('');
                }
				setTalentManagers(talentManagers);
			}

		} catch (e:any) {
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
    }

    const getScadenzario = async() => {
        setLoading(true);
		try{
			const paramsRigheDettaglio:any = {
                kProposta: '',
                beginPeriod: anno+beginPeriod,
                endPeriod: anno+endPeriod,
                kTalentManager: kTalentManager
            }

            let axiosParams = {
                url: 'piani-fatturazione',
                method: 'get',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                params: {
                  ...paramsRigheDettaglio
                }
            }

			let response = await axios(axiosParams);
			if(response.status === 200){
				const data = response.data;
				setRows(data);
				setLoading(false);
			}	
		} catch (e:any) {
			setLoading(false);
			if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
		}
    }

    const modRowFlag = async(value:any, params:any) => {
        /* if(props.proposalTotalDisabled){
            return;
        } */
        try{
            setLoading(true);
            let pianoFattParams:any = {
                kProposta: '', //deve essere stringa vuota dallo scadenzario
                kPianoFatt: params.id,
                dataFatturazione: params.row.DATA_FATTURAZIONE,
                importo: params.row.IMPORTO,
                flagConfermaAttivitaTalent: params.field === 'FLAG_CONFERMA_ATT' ? value : params.row.FLAG_CONFERMA_ATT,
                flagFatturato: params.field === 'FLAG_FATTURATO' ? value : params.row.FLAG_FATTURATO,
                note: params.row.NOTE,
                impersonateKUser: localStorage.getItem('impersonateKUser') ? localStorage.getItem('impersonateKUser') : ''
            }
    
            let axiosParams = {
                url: '',
                method: 'post',
                baseURL: config.API_URL,
                headers: {Authorization: `Bearer ${auth.sessionInfo?.accessToken}`},
                data: {
                  ...pianoFattParams
                }
            }
    
            axiosParams.url = 'piano-fatturazione-mod';
            let response = await axios(axiosParams);
    
            if(response.status === 200){
                await getScadenzario();
                setLoading(false);
            }
        }catch(e:any){
            setLoading(false);
            if(e.response.status===500){
                NotificationManager.error((typeof e.response.data.errorMessage === 'object' ? '' : e.response.data.errorMessage), 'Errore', 10000);
            }else if(e.response.status===401){
                NotificationManager.error('Non Autorizzato', 'Errore', 10000);
                props.logOut();
            }else{
                NotificationManager.error('Errore di Rete', 'Errore', 10000);
            }
            return;
        }
    }

    return (
        <div className='component-container scadenzario'>
            { loading && ( <CircleProgress/>)}
            <div className='component-card'>
                <div className='scadenzario-wrapper'>
                    <div className='filters-section'>
                        <TextField 
                            id="anno" 
                            label="Anno" 
                            variant="standard"
                            value={anno}
                            onChange={(event) => setAnno(event.target.value)} 
                            inputProps={{maxLength: 4}}
                            error={anno.length!==4}
                            sx={{maxWidth:'80px'}}
                        />
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="beginPeriodLabel">Da</InputLabel>
                            <Select
                                labelId="beginPeriodLabel"
                                id="beginPeriod"
                                value={beginPeriod}
                                onChange={(event ) => setBeginPeriod(event.target.value)}
                                label="Da"
                            >
                                {months.map((mese:any) => <MenuItem key={mese.id} value={mese.id}>{mese.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="endPeriodLabel">A</InputLabel>
                            <Select
                                labelId="endPeriodLabel"
                                id="endPeriod"
                                value={endPeriod}
                                onChange={(event ) => setEndPeriod(event.target.value)}
                                label="A"
                            >
                                {months.map((mese:any) => <MenuItem key={mese.id} value={mese.id}>{mese.label}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, maxWidth: 300, width: '100%' }}>
                            <InputLabel id="talentManagerLabel">Talent Manager</InputLabel>
                            <Select
                                labelId="talentManagerLabel"
                                id="talentManager"
                                value={kTalentManager}
                                onChange={(event ) => setKTalentManager(event.target.value)}
                                label="Talent Manager"
                            >
                                <MenuItem key={'0'} value={''}>Tutti</MenuItem>
                                {talentManagers.map((tm:any) => <MenuItem key={tm.id} value={tm.id}>{tm.NOME} {tm.COGNOME}</MenuItem>)}
                            </Select>
                        </FormControl>
                    </div>
                    <DataGridPremium
                        className='dataGrid'
                        slots={{
                            noRowsOverlay: NoRowsDataGridComponent,
                            toolbar: CustomToolbar
                        }}
                        initialState={{ pinnedColumns: { left: ['ACTION'], right: ['FLAG_CONFERMA_ATT', 'FLAG_FATTURATO'] } }}
                        columnVisibilityModel={hiddenCols}
                        rows={rows}
                        columns={columns}
                        rowHeight={30}
                        hideFooter={true}
                        isCellEditable={(params) => params.id!==0}
                        disableColumnMenu={false}
                        disableColumnReorder={true}
                        disableAggregation={true}
                        disableColumnFilter={true}
						disableRowGrouping={true}
                        getRowClassName={(params) => params.row.KSTATO === '06' ? 'red-row' : !params.id ? 'non-editable-row' : ''}
                        pinnedRows={{bottom: [], top: [rows.length > 0 ? rows.find((row:any) => row.id===0) : {id:0}]}}
                    />
                </div>
            </div>
        </div>
    )
}

export default Scadenzario